import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ModalAlertProps } from "../types";
import navbarImages from "../../../assets/images/navbar";
import { HostCard } from "../../../lib/components/carTools/carForm/hostCard/HostCard";
import { useContext, useState } from "react";
import { ShowContext } from "../../../views/car/show/types";
import "./FirstMessage.scss";
import { carShortInformation } from "../../../lib/helpers/textHandler";
import { useQuery } from "react-query";
import {
  getFileDetailsIfExist,
  uploadioEnv,
} from "../../../services/fileUploader";
import authenticationImages from "../../../assets/images/authentication";
import carImagesIndex from "../../../assets/images/car";
import { Skeleton } from "@mui/material";
import { useFormik } from "formik";
import { MessageForm, modalMessageSchema } from "./form";
import { createFirstMessage } from "../../../services/chat";
import { AxiosError } from "axios";
import { Link } from "react-router-dom";

export const FirstMessageModal = ({
  isOpen,
  setOpen,
  setOpenAlert,
  setErrorMsg,
}: ModalAlertProps) => {
  const { t } = useTranslation(["modals", "routes"]);
  const { car, host } = useContext(ShowContext);
  const [messageSent, setMessageSent] = useState<boolean>(false);
  const { data: carImages, isLoading } = useQuery(
    ["image", car?.id],
    () =>
      getFileDetailsIfExist(
        `/${uploadioEnv}/user-${car?.userId}/car-${car?.id}/car_photo_1`,
        `/${uploadioEnv}/user-${car?.userId}/car-${car?.id}`
      ),
    { enabled: !!car }
  );
  const { data: hostImage } = useQuery(
    ["hostImage", host?.id],
    () =>
      getFileDetailsIfExist(
        `/${uploadioEnv}/user-${host?.id}/foto_perfil`,
        `/${uploadioEnv}/user-${host?.id}`
      ),
    {
      enabled: !!host,
    }
  );

  const fetchMessage = async (values: MessageForm) => {
    if (!car) return;

    try {
      await createFirstMessage({
        id: car.id || 0,
        data: values.message,
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        setErrorMsg(error.response?.data.message);
        setOpenAlert(true);
        return;
      }
    }

    setMessageSent(true);
  };

  const formikMessageModal = useFormik<MessageForm>({
    initialValues: {
      message: "",
    },
    validationSchema: modalMessageSchema,
    onSubmit: fetchMessage,
  });

  const CarImage =
    carImages === undefined && !isLoading ? (
      <img
        className="empty-image"
        style={{
          opacity: "0.6",
        }}
        alt="car no image"
        src={carImagesIndex.defaultCar}
      />
    ) : isLoading ? (
      <Skeleton variant="rectangular" className="skeleton" />
    ) : (
      <img src={carImages?.fileUrl} />
    );

  return (
    <Modal show={isOpen} className="first-message-modal">
      <div className="modal-header">
        <button type="button" className="close" onClick={setOpen}>
          <img src={navbarImages.cross} alt="" />
        </button>

        {messageSent ? (
          <div className="sent-message-title">
            <div className="sent-title">
              <h2>{t("modals:first_message.message_sent")}</h2>
              <p>{t("modals:first_message.wait_response")}</p>
            </div>
            <img
              src={
                hostImage
                  ? hostImage.fileUrl.replace("raw", "profile-picture") +
                    `?v=${hostImage.etag}`
                  : authenticationImages.profileDefault
              }
              className="user-img"
              alt=""
            />
          </div>
        ) : (
          <h2 className="title">{t("modals:first_message.contact_host")}</h2>
        )}
      </div>
      {!messageSent && (
        <div className="modal-body">
          <div className="card-container">
            <HostCard userId={host?.id || 0} />
          </div>
          {car && (
            <div className="card-car-container">
              <div className="card-information">
                <p>{carShortInformation(car)}</p>
                <span>Placas {car.plate}</span>
                <button className="btn btn-reserve">
                  {t("modals:first_message.reserve_now")}
                </button>
              </div>
              <div className="card-image">{CarImage}</div>
            </div>
          )}
        </div>
      )}
      <div className="modal-footer">
        <hr className="line" />

        {messageSent ? (
          <p className="remember">{t("modals:first_message.remember")}</p>
        ) : (
          <div className="message-form">
            <p className="title">
              {t("modals:first_message.write_your_message")}
            </p>
            <textarea
              name=""
              className="message-input"
              id="message"
              value={formikMessageModal.values.message}
              onChange={(e) => {
                formikMessageModal.setFieldValue("message", e.target.value);
              }}
              placeholder={t("modals:first_message.placeholder")}
              maxLength={200}
            ></textarea>
          </div>
        )}

        {messageSent ? (
          <div className="sent-buttons">
            <button type="button" className="btn btn-orange" onClick={setOpen}>
              {t("modals:first_message.reserve_now")}
            </button>
            <Link to={"/" + t("routes:drivers") + "/" + t("routes:chat")}>
              {t("modals:first_message.ready")}
            </Link>
          </div>
        ) : (
          <button
            type="button"
            className="btn btn-orange"
            onClick={() => formikMessageModal.handleSubmit()}
          >
            {t("modals:first_message.send_message")}
          </button>
        )}
      </div>
    </Modal>
  );
};
