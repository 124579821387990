import { Trans, useTranslation } from "react-i18next";
import "./CreateReservation.scss";
import carImages from "../../../assets/images/car";
import h_homepageImages from "../../../assets/images/home/h_homepage";
import {
  Checkbox,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  FormControl,
} from "@mui/material";
import { useEffect, useState, ChangeEvent, useMemo } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useQuery } from "react-query";
import {
  getTrip,
  getTripPaymentStatus,
  updateTripPayId,
  updateTripStatus,
} from "../../../services/trip";
import { getUserByID, getUserInsuranceData } from "../../../services/user";
import { getInsuranceByCarId } from "../../../services/insurance";
import { getCar } from "../../../services/car";
import { Card, CardImgIndex } from "../../../models/card/types";
import {
  carShortInformation,
  formatIdHexToDecimal,
  formatNumber,
  numberSeparator,
} from "../../../lib/helpers/textHandler";
import { ChangeCardModal } from "../../../components/modals/changeCardToPay/component";
import { AddCardToPay } from "../../../components/modals/addCardToPay/component";
import useCreateReservation from "../../../hooks/reservations/create/useCreateReservation";
import { List, ListItem } from "@mui/material";
import { SelectedPlan } from "../../../services/payment";
import Toast from "../../../components/toast/component";
import { HostCard } from "../../../lib/components/carTools/carForm/hostCard/HostCard";
import { TooltipIcon } from "../../../components/tooltips/component";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { formatPrice } from "../../../components/carResultCard/utils";
import { getHostByUserId, getHostExistByUserId } from "../../../services/host";
import { isValidHostCOwnInsurance } from "../../../lib/helpers/status";
import { getUserId } from "../../../services/localstorage";
import { TripStatus } from "../../../models/trip/types";
import { InsuranceBanner } from "../../../lib/components/driverReservationTools/insuranceBanner/InsuranceBanner";
import { CouponContainer } from "../../../lib/components/driverReservationTools/couponContainer/couponContainer";
import { encryptId } from "../../../lib/helpers/encrypt";
import { Helmet } from "react-helmet";
import { CompleteProfile } from "../../../components/modals/completeProfile/CompleteProfile";
import { RentTable } from "../../../lib/components/driverReservationTools/rentTable/RentTable";
import { PaymentDTO } from "../../../models/trip/dtos";
import { TrustedUser } from "../../../models/user/types";
import { AxiosError } from "axios";
import { UserInsurance } from "../../../models/userInsurance/types";

export const CreateReservation = () => {
  const { t } = useTranslation(["driverReservation", "car", "routes"]);
  const [termsAndUse, setTermsAndUse] = useState<boolean>(false);
  const [leasingContract, setLeasingContract] = useState<boolean>(false);
  const [openCardModal, setOpenCardModal] = useState<boolean>(false);
  const [openCompleteProfileModal, setOpenCompleteProfileModal] =
    useState<boolean>(false);
  const [openAddModal, setOpenAddModal] = useState<boolean>(false);
  const [newCardUsed, setNewCardUsed] = useState<boolean>(false);
  const [selectedCard, setSelectedCard] = useState<Card>();
  const [msiChecked, setMsiChecked] = useState<boolean>(false);
  const [plans, setPlans] = useState<SelectedPlan[]>([]);
  const [openAlert, setOpenAlert] = useState(false);
  const currentUserId = getUserId();
  const [search] = useSearchParams();
  const [isSubmiting, setIsSubmiting] = useState(false);

  const navigate = useNavigate();

  const { tripId: id } = useParams();
  const tripId = useMemo(() => id && formatIdHexToDecimal(id), [id]);

  const {
    confirmReservation,
    errorMsg,
    setErrorMsg,
    cardPlanes,
    setCardSelectedPlan,
    paymentIntent,
  } = useCreateReservation({
    newCardUsed,
    setSelectedCard,
    setOpenAlert,
    selectedCardId: selectedCard?.id,
    tripId,
  });

  const { data: trip, isSuccess: tripSuccess } = useQuery(
    ["trip"],
    () => getTrip(tripId),
    {
      enabled: !!tripId,
    }
  );

  const { data: car } = useQuery(
    ["car", trip?.carId],
    () => getCar(trip?.carId.toString()),
    {
      enabled: tripSuccess,
    }
  );

  const { data: insuranceCar } = useQuery(
    ["insuranceCar", trip?.carId],
    () => getInsuranceByCarId(trip?.carId),
    {
      enabled: tripSuccess,
    }
  );

  const { data: isHostCommercial } = useQuery(
    ["isHostCommercial", trip?.hostId],
    () => getHostExistByUserId(trip?.hostId.toString() || ""),
    {
      enabled: !!trip?.hostId,
    }
  );

  const existHost = isHostCommercial || false;
  const { data: hostC } = useQuery(
    ["hostC", trip?.hostId],
    () => getHostByUserId(trip?.hostId.toString() || ""),
    {
      enabled: existHost,
    }
  );

  const { data: userData } = useQuery(
    ["user", trip?.driverUserId],
    () => getUserByID(trip?.driverUserId),
    {
      enabled: tripSuccess,
    }
  );

  const { data: userInsuranceData } = useQuery(
    ["userInsurance"],
    getUserInsuranceData
  );

  // Get params when return from 3D Secure
  useEffect(() => {
    const handlePayment = async (
      payment: PaymentDTO,
      userInsuranceData: UserInsurance
    ) => {
      const paymentStatus = await getTripPaymentStatus(payment);

      if (paymentStatus) {
        try {
          await updateTripStatus(parseInt(payment.tripCode), {
            status: TripStatus.SEND_REQUEST_RESERVATION,
          });
          navigate(
            `/${t("trips", {
              ns: "routes",
            })}/${id}`
          );
        } catch (error) {
          if (error instanceof AxiosError) {
            setErrorMsg(
              error.response?.data.message === "User Insurance Data Incomplete"
                ? t("create.error_msg_complete")
                : error.response?.data.message
            );
            setOpenAlert(true);
          }
        }
      } else {
        setOpenAlert(true);
        setErrorMsg(t("create.secure_failed"));
      }
    };

    const paymentId = search.get("payment_intent");
    if (paymentId && tripId && userInsuranceData) {
      handlePayment({ paymentId, tripCode: tripId }, userInsuranceData.data);
    }
  }, [search, tripId, userInsuranceData]);
  // Protect route (status/users)
  useEffect(() => {
    if (trip && currentUserId) {
      (currentUserId !== trip.driverUserId.toString() ||
        trip.status !== TripStatus.CREATE_RESERVATION) &&
        navigate(
          "/" +
            t("results", {
              ns: "routes",
            })
        );
    }
  }, [trip, currentUserId]);

  // Handle select card plan option
  const handleMSI = (_: ChangeEvent<HTMLInputElement>, value: string) => {
    const [planItem] = plans?.filter((item) => item.count.toString() === value);
    setCardSelectedPlan(planItem);
  };

  // Stripe payment intent confirmation
  const handleConfirm = async () => {
    setIsSubmiting(true);
    if (!tripId || !selectedCard?.id) {
      setIsSubmiting(false);
      return;
    }

    // Update stripe payment Id if is new card
    if (newCardUsed && paymentIntent && selectedCard.id) {
      await updateTripPayId(parseInt(tripId), paymentIntent);
    }

    // Update status to confirm reservation
    const response = await confirmReservation(tripId);

    if (!response || response.status !== 200 || response instanceof Error) {
      setOpenAlert(true);
      setIsSubmiting(false);
      return;
    }

    if (response.data.status === "requires_action") {
      // 3D Secure confirmation required
      window.location.assign(response.data.nextAction.url);
    } else if (response.data.status === "requires_capture") {
      try {
        await updateTripStatus(parseInt(tripId), {
          status: TripStatus.SEND_REQUEST_RESERVATION,
        });
        setIsSubmiting(false);
        navigate(`/checkout/checkout_successful?checkout_id=${id}`);
        return;
      } catch (error) {
        if (error instanceof AxiosError) {
          setErrorMsg(
            error.response?.data.message === "User Insurance Data Incomplete"
              ? t("create.error_msg_complete")
              : error.response?.data.message
          );
          setIsSubmiting(false);
          setOpenAlert(true);
        }
      }
    }
    setIsSubmiting(false);
    navigate(`/checkout/checkout_failed?checkout_id=${id}`);
  };

  useEffect(() => {
    cardPlanes.length > 0 ? setPlans(cardPlanes) : setPlans([]);
  }, [cardPlanes]);

  const carImagesIndex = carImages as CardImgIndex;

  const styles = {
    tripButton: {
      backgroundColor: !(leasingContract && termsAndUse)
        ? "#82828282"
        : "#f76f8e",
    },
  };

  const depositWarning = () => {
    if (userData?.trustedUser !== TrustedUser.TRUSTED) return;

    return (
      <div className="warning">
        <div className="text-container">
          <img
            src={carImages.warningTriangle}
            alt=""
            className="alert-triangle"
          />
          <p>
            <Trans>
              {hostC && isValidHostCOwnInsurance(hostC)
                ? t("create.warning", {
                    deposit: `$${numberSeparator(
                      parseFloat(insuranceCar?.depositAmount || "0")
                    )}`,
                  })
                : t("create.warning_personal", {
                    deposit: `$${numberSeparator(
                      parseFloat(insuranceCar?.depositAmount || "0")
                    )}`,
                  })}
            </Trans>
          </p>
        </div>
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <title>
          {car && t("seo.create.title", { car: carShortInformation(car) })}
        </title>
        <meta name="description" content={t("seo.create.description")} />
        <meta name="robots" content="noindex,follow" />
        <link
          rel="alternate"
          hrefLang="es"
          href={`https://romio.mx/checkout/${id}`}
        />
        <link
          rel="alternate"
          hrefLang="en"
          href={`https://romio.mx/en/checkout/${id}`}
        />
        <link
          rel="alternate"
          hrefLang="x-default"
          href={`https://romio.mx/checkout/${id}`}
        />
      </Helmet>
      <Toast open={openAlert} setOpen={setOpenAlert} type="error">
        {errorMsg}
      </Toast>
      <div className="details">
        <div className="details-container">
          <nav className="details-navbar">
            <img src={carImages.arrowLeft} alt="" />
            <p>{t("create.confirm_details")}</p>
          </nav>

          <div className="return-container-des">
            <div className="return">
              <Link
                className="d-flex text-decoration-none"
                to={
                  "/" +
                  t("details", { ns: "routes" }) +
                  "/" +
                  encryptId(trip?.carId.toString() || "0")
                }
              >
                <img src={h_homepageImages.unPrevDes} alt="" />
                <span>{t("create.return")}</span>
              </Link>
            </div>
            <p data-testid="confirm-details">{t("create.confirm_details")}</p>
          </div>

          {trip && (
            <HostCard className="checkout-host-card" userId={trip.hostId} />
          )}

          {car && trip && <RentTable car={car} trip={trip} />}

          <div className="card pay-info">
            <div className="card-header">
              <h2 data-testid="price-detail-title">
                {t("create.price_detail")}
              </h2>
            </div>
            <div className="card-body">
              <div className="extra d-flex col-sm-12 col-md-12 justify-content-between">
                <div className="day-tag">
                  <h5 data-testid="car-label">
                    {t("car:show.car_form.trip_form.car")}
                  </h5>
                  <TooltipIcon
                    title={t("car:show.car_form.information_tooltips.use_days")}
                    icon={<InfoOutlinedIcon />}
                    classes="tooltip-form"
                  />
                  <p className="price-day">
                    $
                    {trip &&
                      car &&
                      car.price +
                        ` / día (x${Math.floor(
                          parseFloat(trip.carPrice) / parseFloat(car.price)
                        )})`}
                  </p>
                </div>
                <span id="totalDays">
                  $
                  {numberSeparator(
                    parseFloat(trip?.carPrice ? trip?.carPrice : "")
                  )}
                </span>
              </div>
              <div className="extra col-sm-12 col-md-12">
                <div className="day-tag">
                  <h5 data-testid="insurance-label">{t("create.insurance")}</h5>
                  <TooltipIcon
                    title={t(
                      "car:show.car_form.information_tooltips.insurance"
                    )}
                    icon={<InfoOutlinedIcon />}
                    classes="tooltip-form"
                  />
                  <p className="insurance-day">
                    $
                    {trip &&
                      insuranceCar &&
                      insuranceCar.customerPrice +
                        ` / día (x${
                          trip.insurancePrice === "0" ||
                          insuranceCar.customerPrice === "0"
                            ? "1"
                            : Math.floor(
                                parseFloat(trip.insurancePrice) /
                                  parseFloat(insuranceCar.customerPrice)
                              )
                        })`}
                  </p>
                </div>
                <span id="seguro">
                  $
                  {numberSeparator(
                    +parseFloat(trip?.insurancePrice || "").toFixed(2)
                  )}
                </span>
              </div>
              <div className="extra col-sm-12 col-md-12">
                <div className="day-tag">
                  <h5 data-testid="booking_fee-label">
                    {t("driverReservation:create.booking_fee")}
                  </h5>
                  <TooltipIcon
                    title={t(
                      "car:show.car_form.information_tooltips.comission"
                    )}
                    icon={<InfoOutlinedIcon />}
                    classes="tooltip-form"
                  />
                </div>
                <span id="bookingFee">${trip?.bookingFeePrice}</span>
              </div>
              <div className="extra col-sm-12 col-md-12">
                <div className="day-tag">
                  <h5 data-testid="delivery-return-label">
                    {t("car:show.car_form.trip_form.delivery_return")}
                  </h5>
                  <TooltipIcon
                    title={t("car:show.car_form.information_tooltips.prices")}
                    icon={<InfoOutlinedIcon />}
                    classes="tooltip-form"
                  />
                </div>
                <span id="entregaDevolucion">${trip?.pickUpReturnPrice}</span>
              </div>
              {trip && <CouponContainer trip={trip} />}
              <div className="total col-sm-12 col-md-12">
                <h5
                  className="mr-5"
                  data-testid="total-cost-label"
                  style={{ marginRight: "10px" }}
                >
                  {t("car:show.car_form.trip_form.total_cost")}
                </h5>
                <p className="txtTotalPrice">
                  MXN
                  <span>
                    {trip &&
                      formatPrice(
                        parseFloat(trip.totalPrice || "") -
                          parseFloat(
                            trip.discount !== " " ? trip.discount : "0"
                          )
                      )}
                  </span>
                </p>
              </div>
              {depositWarning()}

              <div className="checkboxes">
                <div className="checkbox-form col-sm-12 col-md-12">
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={termsAndUse}
                        onChange={() => setTermsAndUse(!termsAndUse)}
                        color="balety"
                      />
                    }
                    className="check-terms-form"
                    label={
                      <div className="label-terms">
                        <p>
                          {t("create.accept")}
                          <a
                            target="_blank"
                            href={
                              "https://upcdn.io/12a1xrm/raw/balety.com/documents/terminos-y-condiciones-de-uso.pdf"
                            }
                          >
                            {t("create.accept_terms_and_conditions")}
                          </a>
                        </p>
                      </div>
                    }
                  />
                  {!termsAndUse ? (
                    <p id="check-error" className="check-error">
                      <img src={carImages.cross} />
                      <span>{t("create.accept_terms")}</span>
                    </p>
                  ) : null}
                </div>
                <div className="checkbox-form col-sm-12 col-md-12">
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={leasingContract}
                        onChange={() => setLeasingContract(!leasingContract)}
                        color="balety"
                      />
                    }
                    className="check-terms-form"
                    label={
                      <div className="label-terms">
                        <p>
                          Acepto el{" "}
                          <a
                            target="_blank"
                            href={
                              "https://upcdn.io/12a1xrm/raw/balety.com/documents/contrato-de-arrendamiento.pdf"
                            }
                          >
                            {t("create.leasing_contract")}
                          </a>
                        </p>
                      </div>
                    }
                  />
                  {!leasingContract ? (
                    <p id="check-error" className="check-error">
                      <img src={carImages.cross} />
                      <span>{t("create.accept_leasing_contract")}</span>
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="pay-and-change col-sm-12 col-md-12">
                <div className="pay-method">
                  <p className="pay-method-label">
                    {t("create.payment_method")}
                  </p>
                  {selectedCard && (
                    <div className="card-item">
                      <div className="card-data-container">
                        <div className="card-data">
                          <div className="card-data-left">
                            <img
                              className="card-brand"
                              src={
                                carImagesIndex[selectedCard.brand] ||
                                carImages.generic
                              }
                            />
                            <p className="card-num">
                              {`**** **** **** ${selectedCard?.last4}`}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div>
                  <button
                    id="payMethodButton"
                    type="button"
                    data-toggle="modal"
                    data-target="#list_pay_cards"
                    className="btn btn-change btn-add-pay-method"
                    onClick={() => setOpenCardModal(true)}
                  >
                    {t("create.change")}
                  </button>
                </div>
              </div>
              {plans?.length > 0 && (
                <div className="col-sm-12 col-md-12 my-2">
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={msiChecked}
                        onChange={() =>
                          setMsiChecked((msiChecked) => !msiChecked)
                        }
                        color="balety"
                      />
                    }
                    label={t("create.pay-with-msi")}
                  />
                </div>
              )}
              {msiChecked && plans?.length > 0 && (
                <div className="pay-and-change col-sm-12 col-md-12 my-4">
                  <FormControl className="d-flex flex-column form-msi">
                    <div>
                      <FormLabel className="pay-options-label">
                        {t("create.card-payment-options")}
                      </FormLabel>
                    </div>
                    <List
                      sx={{
                        border: ".1px solid #e0e0e0",
                        borderRadius: "8px",
                        width: "100%",
                        marginTop: "8px",
                        padding: 0,
                      }}
                    >
                      <RadioGroup onChange={(e, value) => handleMSI(e, value)}>
                        {plans.map((item: SelectedPlan, index) => (
                          <ListItem
                            key={item.count}
                            alignItems="flex-start"
                            sx={
                              index !== plans.length - 1
                                ? {
                                    borderBottom: ".1px solid #e0e0e0",
                                    flexDirection: "column",
                                  }
                                : { flexDirection: "column" }
                            }
                          >
                            <FormControlLabel
                              value={item.count}
                              control={<Radio />}
                              label={`${item.count} ${t(
                                "create.pay-with-msi-calculation"
                              )} ${
                                trip?.totalPrice
                                  ? formatNumber(
                                      parseInt(trip?.totalPrice) / item.count
                                    )
                                  : ""
                              } MXN`}
                              sx={{ paddingRight: "4px" }}
                            />
                            {trip?.totalPrice && (
                              <p className="form-msi-total">
                                {`Total: ${formatNumber(
                                  parseInt(trip?.totalPrice)
                                )} MXN`}
                              </p>
                            )}
                          </ListItem>
                        ))}
                      </RadioGroup>
                    </List>
                  </FormControl>
                </div>
              )}
            </div>
          </div>

          <div className="insurance-container-mobile">
            {trip && <InsuranceBanner trip={trip} />}
          </div>

          <div className="total-fixed">
            <div className="total-price-information">
              <h5
                className="mr-5"
                data-testid="total-cost-label"
                style={{ marginRight: "10px" }}
              >
                {t("car:show.car_form.trip_form.total_cost")}
              </h5>
              <p className="txtTotalPrice">
                <span>
                  {trip &&
                    formatPrice(
                      parseFloat(trip.totalPrice || "") -
                        parseFloat(trip.discount !== " " ? trip.discount : "0")
                    )}
                </span>
                MXN
              </p>
            </div>
            <div className="for">
              <button
                type="button"
                id="check-confirm"
                style={styles.tripButton}
                onClick={handleConfirm}
                disabled={
                  !(leasingContract && termsAndUse && selectedCard) ||
                  isSubmiting
                }
                className="btn btn-confirm confirm-payment"
              >
                {t("create.confirm_and_book")}
              </button>
            </div>
          </div>
          <div className="confirm-des">
            <div className="check-button">
              <div className="checkboxes">
                <div className="checkbox-form">
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={termsAndUse}
                        onChange={() => setTermsAndUse(!termsAndUse)}
                        color="balety"
                      />
                    }
                    className="check-terms-form"
                    data-testid="terms-and-conditions"
                    label={
                      <div className="label-terms">
                        <p>
                          {t("create.accept")}
                          <a
                            target="_blank"
                            href={
                              "https://upcdn.io/12a1xrm/raw/balety.com/documents/terminos-y-condiciones-de-uso.pdf"
                            }
                          >
                            {t("create.accept_terms_and_conditions")}
                          </a>
                        </p>
                      </div>
                    }
                  />
                  {!termsAndUse ? (
                    <p id="check-error" className="check-error">
                      <img src={carImages.cross} />
                      <span>{t("create.accept_terms")}</span>
                    </p>
                  ) : null}
                </div>
                <div className="checkbox-form">
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={leasingContract}
                        onChange={() => setLeasingContract(!leasingContract)}
                        color="balety"
                      />
                    }
                    className="check-terms-form"
                    label={
                      <div className="label-terms">
                        <p>
                          {t("create.accept_leasing")}
                          <a
                            target="_blank"
                            href={
                              "https://upcdn.io/12a1xrm/raw/balety.com/documents/contrato-de-arrendamiento.pdf"
                            }
                          >
                            {t("create.leasing_contract")}
                          </a>
                        </p>
                      </div>
                    }
                  />
                  {!leasingContract ? (
                    <p id="check-error" className="check-error">
                      <img src={carImages.cross} />
                      <span>{t("create.accept_leasing_contract")}</span>
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="form">
                <button
                  onClick={handleConfirm}
                  type="button"
                  id="checkDes-confirm"
                  disabled={
                    !(
                      leasingContract &&
                      termsAndUse &&
                      selectedCard &&
                      errorMsg === null
                    ) || isSubmiting
                  }
                  style={styles.tripButton}
                  className="btn btn-confirm confirm-payment"
                  data-testid="confirm-and-book"
                >
                  {t("create.confirm_and_book")}
                </button>
              </div>
            </div>
            <div className="insurance-container-desktop">
              {trip && <InsuranceBanner trip={trip} redirect={false} />}
            </div>
          </div>
        </div>
      </div>
      <CompleteProfile
        isOpen={openCompleteProfileModal}
        setOpen={setOpenCompleteProfileModal}
      />
      <ChangeCardModal
        isOpen={openCardModal}
        setOpen={setOpenCardModal}
        setOpenAdd={setOpenAddModal}
        setSelectedCard={setSelectedCard}
        paymentIntentId={paymentIntent || undefined}
      />
      <AddCardToPay
        isOpen={openAddModal}
        setOpen={setOpenAddModal}
        setSelectedCard={setSelectedCard}
        setNewCardUsed={setNewCardUsed}
        type="checkout"
      />
    </>
  );
};
