import { HostSetting } from "../models/hostSetting/types";
import { PortalPaletteForm } from "../views/portal/form";
import { axiosService } from "./axios";
import { getToken } from "./localstorage";

export const getHostSettings = async (domain: string) => {
  try {
    const response = await axiosService.post<HostSetting>(
      "/hostsettings/domain",
      { domain }
    );

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const updateHostSettings = async (palette: PortalPaletteForm) => {
  try {
    const response = await axiosService.patch("/hostsettings/update", palette, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error(error);
  }
};
