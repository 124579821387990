import { useState, useEffect, useCallback } from "react";
import {
  deleteFile,
  updateFile,
  listFiles,
  uploadioEnv,
} from "../../services/fileUploader";
import { getUserId } from "../../services/localstorage";
import { arrayMoveImmutable } from "array-move";

export default function useUploaderCarGallery(
  carId: number,
  editionMode: boolean
) {
  const userId = getUserId();
  // Local images that can be uploaded
  const [items, setItems] = useState<
    { preview: string; name: string; type: string }[]
  >([]);
  // When exceding the limit of images
  const [locked, setLocked] = useState<boolean>(false);
  // Loading when editioMode is true
  const [loading, setLoading] = useState<boolean>(editionMode);

  // To handle the drag and drop functionality
  const onSortEnd = (oldIndex: number, newIndex: number) => {
    setItems((array) => arrayMoveImmutable(array, oldIndex, newIndex));
  };
  // Delete the image from the local list
  const handleRemoveImage = useCallback(
    async (id: string) => {
      try {
        const index = items.findIndex((item) => item.preview === id);
        if (index === -1) return;
        // Always delete last image (to move the order of the images)
        await deleteFile(
          `/${uploadioEnv}/user-${userId}/car-${carId}/car_photo_${items.length}`
        );
        // Update array
        setItems((prev) => prev.filter((item) => item.preview !== id));
      } catch (error) {
        console.error("Error deleting file", error);
      }
    },
    [items]
  );

  // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
  useEffect(() => {
    return () =>
      items.forEach((file: { preview: string }) =>
        URL.revokeObjectURL(file.preview)
      );
  }, []);

  // Overwrite items
  useEffect(() => {
    setLocked(items.length === 6);
    (async () => {
      items.forEach((file, i) => {
        updateFile(
          `/${uploadioEnv}/user-${userId}/car-${carId}/car_photo_${i + 1}`,
          file as unknown as File,
          file.type
        );
      });
    })();
  }, [items]);

  // Get dinamically the image source
  useEffect(() => {
    if (!editionMode) return;
    (async () => {
      const existingCars: File[] = [];
      try {
        const result = await listFiles(
          `/${uploadioEnv}/user-${userId}/car-${carId}`
        );
        if (!result || !result.items || result.items.length == 0) return;
        for (const file of result.items) {
          if (!file.filePath) continue;
          if (file.filePath.includes("car_photo_")) {
            try {
              const response = await fetch(file.fileUrl + `?v=${file.etag}`);
              const data = await response.blob();
              const car = new File([data], file.filePath.split("/").at(-1), {
                type: "image/png",
              });
              existingCars.push(
                Object.assign(car, {
                  preview: URL.createObjectURL(car),
                })
              );
            } catch (error) {
              console.error("failed to get blob", error);
            } finally {
              setItems([...existingCars] as never);
            }
          }
        }
      } catch (_) {
      } finally {
        setLoading(false);
      }
    })();
  }, [editionMode]);

  return {
    locked,
    onSortEnd,
    items,
    handleRemoveImage,
    setItems,
    loading,
  };
}
