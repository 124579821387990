import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getDaysArray } from "../lib/helpers/dateHandler";
import { Dates } from "../models/date/types";
import { getDateByCarID } from "../services/date";
import { EditDateBlock } from "./../lib/components/carTools/editCarPrice/types";

export const useDisableDates = ({ carId }: { carId: string }) => {
  const [blockDates, setBlockDates] = useState<EditDateBlock[]>([]);
  const [isBlocked, setIsBlocked] = useState(false);

  const { data: disabledDates } = useQuery(
    ["blockedDates", carId],
    () => getDateByCarID(parseInt(carId)),
    {
      enabled: carId !== "0",
    }
  );

  useEffect(() => {
    if (disabledDates) {
      handleDisabledDates(disabledDates);
    } else {
      setIsBlocked(false);
    }
  }, [disabledDates]);

  const handleDisabledDates = (disabledDates: Dates[]) => {
    setBlockDates([]);
    if (disabledDates.length === 0) {
      setIsBlocked(true);
      return;
    }

    disabledDates.map((date: Dates) => {
      const list = getDaysArray(date.dateInitial, date.dateEnd);
      setBlockDates((prev) => [
        ...prev,
        {
          id: date.id,
          startDate: dayjs(date.dateInitial, "DD-MM-YYYY").format("DD/MM/YYYY"),
          endDate: dayjs(date.dateEnd, "DD-MM-YYYY").format("DD/MM/YYYY"),
          status: date.status,
          dates: list,
        },
      ]);
    });

    setIsBlocked(true);
  };

  return { blockDates, setBlockDates, isBlocked };
};
