import { FC } from "react";
import { Car } from "../../../../models/car/types";
import { useQuery } from "react-query";
import {
  getFileDetailsIfExist,
  uploadioEnv,
} from "../../../../services/fileUploader";
import { Skeleton } from "@mui/material";
import carImages from "../../../../assets/images/car";

export const TripImage: FC<{ car: Car }> = ({ car }) => {
  const { data: carImage, isLoading } = useQuery(
    ["image", car?.id],
    () =>
      getFileDetailsIfExist(
        `/${uploadioEnv}/user-${car?.userId}/car-${car?.id}/car_photo_1`,
        `/${uploadioEnv}/user-${car?.userId}/car-${car?.id}`
      ),
    { enabled: !!car }
  );

  if (isLoading) {
    return <Skeleton variant="rectangular" height={180} width={"100"} />;
  }

  return (
    <>
      {carImage ? (
        <img
          className="car-img"
          alt=""
          src={
            carImage.fileUrl.replace("raw", "thumbnail-results") +
            `?v=${carImage.etag}`
          }
        />
      ) : (
        <div className="default">
          <img alt="" src={carImages.defaultCar} />
        </div>
      )}
    </>
  );
};
