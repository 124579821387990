import { Trans, useTranslation } from "react-i18next";
import carImages from "../../../assets/images/car";
import h_homepage from "../../../assets/images/home/h_homepage";
import reservationsImages from "../../../assets/images/reservations";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  ActiveActions,
  CancelActions,
  Contracts,
  NextActions,
} from "../../../lib/components/driverReservationTools/component";
import { TripStatus } from "../../../models/trip/types";
import "./styles.scss";
import { statusList } from "../../driverReservation/show/types";
import { TripContext } from "../../driverReservation/show/component";
import { Profit } from "../../../lib/components/hostReservationTools/component";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { getTrip } from "../../../services/trip";
import { getUserByID } from "../../../services/user";
import { getCar } from "../../../services/car";
import dayjs from "dayjs";
import {
  getFileDetailsIfExist,
  uploadioEnv,
} from "../../../services/fileUploader";
import Loading from "../../../components/loading/component";
import { Chip } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import reservationImages from "../../../assets/images/reservations";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";
import { getInsuranceByCarId } from "../../../services/insurance";
import {
  formatIdHexToDecimal,
  numberSeparator,
} from "../../../lib/helpers/textHandler";
import { formatPrice } from "../../../components/carResultCard/utils";
import { TooltipIcon } from "../../../components/tooltips/component";
import {
  CarExperiences,
  CarSpecs,
} from "../../../lib/components/carTools/carForm/cardInfo/CardInfo";
import { EmptyState } from "../../../lib/components/resultsTools/emptyState/component";
import { useEffect, useMemo } from "react";
import { DriverCard } from "../../../lib/components/carTools/carForm/driverCard/DriverCard";
import { AddressTextHost } from "../../../lib/components/hostReservationTools/addressTextHost/AddressTextHost";
import { ResponsibilitiesHostBanner } from "../../../lib/components/hostReservationTools/responsibilitiesHostBanner/ResponsibilitiesHostBanner";
import { HostPendingActions } from "../../../lib/components/hostReservationTools/component";
import { Helmet } from "react-helmet";
import { getUserId } from "../../../services/localstorage";
import { InsuranceType } from "../../../models/insurance/types";

export const HostReservation = () => {
  const { t } = useTranslation([
    "driverReservation",
    "hostReservation",
    "routes",
  ]);
  const currentUser = getUserId();
  const navigate = useNavigate();
  const { HostReservationId: id } = useParams();
  const HostReservationId = useMemo(() => id && formatIdHexToDecimal(id), [id]);
  const {
    data: trip,
    isSuccess,
    isLoading,
  } = useQuery(["trip"], () => getTrip(HostReservationId));

  useEffect(() => {
    if (!trip || !currentUser) return;

    if (trip.hostId.toString() !== currentUser) {
      navigate(
        "/" +
          t("results", {
            ns: "routes",
          })
      );
    }
  }, [trip]);

  const { data: driver } = useQuery(
    ["driver", trip?.driverUserId],
    () => getUserByID(trip?.driverUserId),
    { enabled: isSuccess }
  );

  const { data: insuranceCar } = useQuery(
    ["insuranceCar", trip?.carId],
    () => getInsuranceByCarId(trip?.carId),
    {
      enabled: !!trip,
    }
  );

  const { data: car } = useQuery(
    ["car", trip?.carId],
    () => getCar(trip?.carId.toString()),
    { enabled: isSuccess }
  );

  const { data: carImage } = useQuery(
    ["image", trip?.carId],
    () =>
      getFileDetailsIfExist(
        `/${uploadioEnv}/user-${trip?.hostId}/car-${trip?.carId}/car_photo_1`,
        `/${uploadioEnv}/user-${trip?.hostId}/car-${trip?.carId}`
      ),
    { enabled: isSuccess }
  );

  if (isLoading) {
    return <Loading height="25vh" />;
  }

  if (!trip) {
    return <EmptyState />;
  }

  const circleIconColor =
    trip.status === TripStatus.SEND_REQUEST_RESERVATION
      ? "#FFAB03"
      : trip.status === TripStatus.ACCEPT_RESERVATION
      ? "#f76f8e"
      : trip.status === TripStatus.ACTIVE_RESERVATION
      ? "#55DD55"
      : trip.status === TripStatus.FINISH_RESERVATION
      ? "#3CB2B9"
      : trip.status === TripStatus.CANCEL_REQUEST_HUESPED ||
        trip.status === TripStatus.REFUSE_REQUEST_ANFITRION
      ? "#E97451"
      : "#EB5757";

  const circleLabel =
    trip.status === TripStatus.SEND_REQUEST_RESERVATION
      ? t("index.status.pending")
      : trip.status === TripStatus.ACCEPT_RESERVATION
      ? t("index.status.next")
      : trip.status === TripStatus.ACTIVE_RESERVATION
      ? t("index.status.active")
      : trip.status === TripStatus.FINISH_RESERVATION
      ? t("index.status.finished")
      : trip.status === TripStatus.CANCEL_REQUEST_HUESPED ||
        trip.status === TripStatus.REFUSE_REQUEST_ANFITRION
      ? t("index.status.refused")
      : t("index.status.cancel");

  const actions =
    trip.status === TripStatus.SEND_REQUEST_RESERVATION ? (
      <HostPendingActions />
    ) : trip.status === TripStatus.ACCEPT_RESERVATION ? (
      <NextActions />
    ) : trip.status === TripStatus.ACTIVE_RESERVATION ? (
      <ActiveActions />
    ) : trip.status === TripStatus.FINISH_RESERVATION ? (
      <ActiveActions finished />
    ) : trip.status === TripStatus.CANCEL_RESERVATION_ANFITRION ||
      trip.status === TripStatus.CANCEL_RESERVATION_HUESPED ||
      trip.status === TripStatus.CANCEL_RESERVATION_BALETY ? (
      <CancelActions />
    ) : null;

  const profit =
    trip?.status == TripStatus.SEND_REQUEST_RESERVATION ||
    trip?.status == TripStatus.ACCEPT_RESERVATION ||
    trip?.status == TripStatus.ACTIVE_RESERVATION ||
    trip?.status == TripStatus.FINISH_RESERVATION ? (
      <Profit />
    ) : null;

  const total =
    parseFloat(trip.carPrice) +
    parseFloat(trip.insurancePrice) +
    parseFloat(trip.bookingFeePrice) +
    parseFloat(trip.pickUpReturnPrice);

  return (
    <>
      <Helmet>
        <title>{t("seo.show.title", { code: trip.tripCode })}</title>
        <meta name="description" content={t("seo.show.description")} />
        <meta name="robots" content="noindex,follow" />
        <link
          rel="alternate"
          hrefLang="es"
          href={`https://romio.mx/anfitriones/reservaciones/${id}`}
        />
        <link
          rel="alternate"
          hrefLang="en"
          href={`https://romio.mx/en/anfitriones/reservaciones/${id}`}
        />
        <link
          rel="alternate"
          hrefLang="x-default"
          href={`https://romio.mx/anfitriones/reservaciones/${id}`}
        />
      </Helmet>
      <TripContext.Provider value={{ t, trip, host: driver, car }}>
        <div className="host-reservation-container">
          <div className="car-form">
            <div className="card-info">
              <div className="return-container">
                <Link
                  to={`/${t("manage", { ns: "routes" })}/${t("trips", {
                    ns: "routes",
                  })}`}
                  className="reservation-index"
                >
                  <img src={h_homepage.unPrevDes} alt="" />
                  <span data-testid="return-link" className="return-link">
                    <Trans i18nKey="show.reservation_details.title">
                      {t("show.reservation_details.title") +
                        t(
                          "show.reservation_details." +
                            statusList[trip?.status ? trip?.status : 0]
                        )}
                    </Trans>
                  </span>
                </Link>
              </div>

              <DriverCard userId={trip.driverUserId} trip={trip} />

              <button type="button" className="btn btn-confirmation-code">
                {t("driverReservation:show.code") + trip?.tripCode}
              </button>

              <div className="card-car-information">
                <h5 className="card-title">
                  {car?.brand + " " + car?.model + " " + car?.year}
                </h5>
                <h6 className="card-placas">{car?.plate}</h6>
                <article id="" className="return-car">
                  <div style={{ fontSize: "6px" }}>
                    <Chip
                      data-testid="button-status"
                      icon={
                        <CircleIcon
                          fontSize="inherit"
                          style={{
                            color: circleIconColor,
                          }}
                        />
                      }
                      label={circleLabel}
                      sx={{
                        position: "absolute",
                        top: "8px",
                        left: "8px",
                        background: "white",
                        height: "24px",
                        zIndex: 9,
                      }}
                    />
                  </div>
                  <img
                    className="car-img"
                    src={
                      carImage
                        ? carImage.fileUrl.replace(
                            "raw",
                            "thumbnail-reservations"
                          ) + `?v=${carImage.etag}`
                        : reservationsImages.carReservations
                    }
                    alt=""
                  />
                </article>
              </div>

              {car && <CarExperiences car={car} />}
              {car && <CarSpecs car={car} />}

              <div className="contracts-container">
                <Contracts />
              </div>
            </div>

            <div className="card">
              <button type="button" className="btn btn-confirmation-code-des">
                {t("driverReservation:show.code") + trip?.tripCode}
              </button>
              <div className="card-body">
                <div className="un-container-date">
                  <div className="un-desde">
                    <label className="w-100" data-testid="since-label">
                      {t("show.trip_summary.since")}
                    </label>
                    <div className="input-container">
                      <div className="input-text mb-0">
                        <p className="pickUpDate">
                          {dayjs(trip.pickUpDate, "DD-MM-YYYY").format(
                            "DD/MM/YYYY"
                          )}
                        </p>
                      </div>
                      <div className="dropdown-divider"></div>
                      <div className="input-text mb-0">
                        <p className="pickUpTime">
                          {dayjs(trip.pickUpTime, "HH:mm:ss").format("h:mm A")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="un-hasta">
                    <label data-testid="until-label">
                      {t("show.trip_summary.until")}
                    </label>
                    <div className="input-container">
                      <div className="input-text mb-0">
                        <p className="returnDate">
                          {dayjs(trip.returnDate, "DD-MM-YYYY").format(
                            "DD/MM/YYYY"
                          )}
                        </p>
                      </div>
                      <div className="dropdown-divider"></div>
                      <div className="input-text">
                        <p className="returnTime">
                          {dayjs(trip.returnTime, "HH:mm:ss").format("h:mm A")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 delibery">
                  <label data-testid="delivery-label">
                    {t("show.trip_summary.delivery")}
                  </label>
                  <AddressTextHost addressId={trip.pickUpAddressId} />
                </div>
                <div className="col-sm-12 col-md-12 delibery devolution">
                  <label data-testid="return-label">
                    {t("show.trip_summary.return")}
                  </label>
                  <AddressTextHost addressId={trip.returnAddressId} />
                </div>
                <div className="extra d-flex col-sm-12 col-md-12">
                  <div className="day-tag">
                    <h5 data-testid="car-label">
                      {t("show.trip_summary.car")}
                    </h5>
                    <TooltipIcon
                      title={t(
                        "car:show.car_form.information_tooltips.use_days"
                      )}
                      icon={<InfoOutlinedIcon />}
                      classes="tooltip-form"
                    />
                    <p className="price-day">
                      ${car?.price + t("driverReservation:show.by_day")}
                    </p>
                  </div>
                  <span className="carPriceTotal">
                    $
                    {numberSeparator(
                      parseFloat(trip?.carPrice ? trip?.carPrice : "")
                    )}
                  </span>
                </div>
                <div className="dropdown-divider col-sm-12 col-md-12"></div>
                <div className="extra col-sm-12 col-md-12">
                  <div className="day-tag">
                    <h5 data-testid="insurance-label">
                      {t("show.trip_summary.insurance")}
                    </h5>
                    <TooltipIcon
                      title={t(
                        "car:show.car_form.information_tooltips.insurance"
                      )}
                      icon={<InfoOutlinedIcon />}
                      classes="tooltip-form"
                    />
                    <p className="insurance-day">
                      $
                      {insuranceCar?.customerPrice +
                        t("driverReservation:show.by_day")}
                    </p>
                  </div>
                  <span id="insuranceTotal">
                    ${numberSeparator(parseFloat(trip.insurancePrice || ""))}
                  </span>
                </div>
                <div className="dropdown-divider col-sm-12 col-md-12"></div>
                <div className="extra col-sm-12 col-md-12">
                  <div className="day-tag">
                    <h5 data-testid="booking_fee-label">
                      {t("show.trip_summary.booking_fee")}
                    </h5>
                    <TooltipIcon
                      title={t(
                        "car:show.car_form.information_tooltips.comission"
                      )}
                      icon={<InfoOutlinedIcon />}
                      classes="tooltip-form"
                    />
                  </div>
                  <span className="bookingFeePrice">
                    ${trip.bookingFeePrice}
                  </span>
                </div>
                <div className="dropdown-divider col-sm-12 col-md-12"></div>
                <div className="extra col-sm-12 col-md-12">
                  <div className="day-tag">
                    <h5 data-testid="delivery-return-label">
                      {t("show.trip_summary.delivery_return")}
                    </h5>
                    <TooltipIcon
                      title={t("car:show.car_form.information_tooltips.prices")}
                      icon={<InfoOutlinedIcon />}
                      classes="tooltip-form"
                    />
                  </div>
                  <span className="pickUpReturnPrice">
                    ${trip?.pickUpReturnPrice}
                  </span>
                </div>
                <div className="dropdown-divider col-sm-12 col-md-12"></div>
                <div className="total col-sm-12 col-md-12">
                  <h5 className="total-label" data-testid="total-cost-label">
                    {t("show.trip_summary.total_cost")}
                  </h5>
                  <p className="totalPrice">
                    <span>{formatPrice(total)}</span>
                    MXN
                  </p>
                </div>
                {profit}
                {trip.safetyDepositAmount !== "" && (
                  <div className="warning">
                    <button type="button" className="btn alert-triangle">
                      <img src={carImages.alertTriangle} alt="" />
                    </button>
                    <div className="text-container">
                      <p>
                        <Trans>
                          {t("create.warning", {
                            deposit: `$
                        ${numberSeparator(
                          parseFloat(trip.safetyDepositAmount)
                        )}`,
                          })}
                        </Trans>
                      </p>
                    </div>
                  </div>
                )}
                {actions}
              </div>
            </div>

            <div className="contracts-container-mobile">
              <Contracts />
            </div>
          </div>
          <Swiper
            slidesPerView={1}
            loop
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 7000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay, Pagination, Navigation]}
            className="banner-swipper"
          >
            <SwiperSlide>
              <ResponsibilitiesHostBanner
                image={reservationImages.responsibilities}
                mainInformation={t(
                  "hostReservation:index.responsibilities_summary.main_information"
                )}
                title={t(
                  "hostReservation:index.responsibilities_summary.title"
                )}
                link="https://ayuda.balety.com/articulos/responsabilidades-de-anfitrion"
              />
            </SwiperSlide>
            <SwiperSlide>
              {trip.insuranceCompany === InsuranceType.HOST_INSURANCE ? (
                <ResponsibilitiesHostBanner
                  image={reservationImages.insurance}
                  mainInformation={t(
                    "hostReservation:index.host_commercial_insurance.main_information"
                  )}
                  title={t(
                    "hostReservation:index.host_commercial_insurance.title"
                  )}
                  link="https://ayuda.balety.com/articulos/anfitrion-explicacion-de-seguro-seguro-propio-de-anfitrion-comercial"
                />
              ) : trip.insuranceCompany === InsuranceType.QUALITAS ? (
                <ResponsibilitiesHostBanner
                  image={reservationImages.qualitas}
                  mainInformation={t(
                    "hostReservation:index.host_commercial_insurance_qualitas.main_information"
                  )}
                  title={t(
                    "hostReservation:index.host_commercial_insurance_qualitas.title"
                  )}
                  link="https://ayuda.balety.com/articulos/anfitrion-explicacion-del-seguro-con-qualitas"
                />
              ) : (
                <ResponsibilitiesHostBanner
                  image={reservationImages.hdi}
                  mainInformation={t(
                    "hostReservation:index.host_commercial_insurance_hdi.main_information"
                  )}
                  title={t(
                    "hostReservation:index.host_commercial_insurance_hdi.title"
                  )}
                  link=""
                />
              )}
            </SwiperSlide>
            <SwiperSlide>
              <ResponsibilitiesHostBanner
                image={reservationImages.tips}
                mainInformation={t(
                  "hostReservation:index.host_tips.main_information"
                )}
                title={t("hostReservation:index.host_tips.title")}
                link="https://ayuda.balety.com/articulos/tips-para-ser-un-super-anfitrion"
              />
            </SwiperSlide>
          </Swiper>
        </div>
      </TripContext.Provider>
    </>
  );
};
