import navbarImages from "../../../../assets/images/navbar";
import UploadDropZone from "../../../../components/uploadDropZone/component";
import useAddCarDetails from "./useAddCarDetails";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { ValidateInfo, DocInsuranceCover } from "../../../../models/car/types";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import { getUserId } from "../../../../services/localstorage";
import { uploadioEnv } from "../../../../services/fileUploader";

/**
 * ADD CAR DETAILS - step 5: circulation card, insurense and goals
 */
export const AddCarDetails = ({ carId }: { carId: string }) => {
  const {
    t,
    values,
    frequencyShare,
    setStatusText,
    setStatusSerialNumberText,
    setStatusSerialNumberIcon,
    setStatusIcon,
    setFieldValue,
    handleQA,
    goal,
    frequencyUse,
    circulationCardFile,
    setCirculationCardFile,
    insurancePolicyFile,
    setInsurancePolicyFile,
    isLoading,
  } = useAddCarDetails(carId || "");

  return (
    <div className="model-ubication" id="first_step">
      <div className="form-container">
        <div className="info-container">
          <h2 data-testid="model-and-ubication-title">
            {t("add.car_security.documents_title")}
          </h2>
          <h3>{t("add.car_security.documents_subtitle")}</h3>
        </div>
        <div className="form">
          <Accordion className="file-container" defaultExpanded>
            <AccordionSummary
              expandIcon={
                <img className="drop-row" src={navbarImages.downArrow} alt="" />
              }
              className="d-flex justify-content-between accordion-summary"
              id="drop_arrow"
            >
              {setStatusSerialNumberIcon()}
              <div className="title-container">
                <p className="option-title">
                  {t("add.car_security.serial_number.title")}
                </p>
                {setStatusSerialNumberText()}
              </div>
            </AccordionSummary>
            <AccordionDetails className="serial-number" id="card_file">
              <div className="serial-info">
                <p>{t("add.car_security.serial_number.info")}</p>
                <a className="extra-info-p">
                  {t("add.car_security.serial_number.how_to_find")}
                </a>
              </div>
              <div className="serial-container-input">
                <label className="serial-label">
                  {t("add.car_security.serial_number.title")}
                </label>
                <TextField
                  className="serial-number-input"
                  onChange={(e) => setFieldValue("niv", e.target.value.trim())}
                  placeholder={t("add.car_security.serial_number.placeholder")}
                />
              </div>
              {values.status === 0 && values.niv === "" ? (
                <p className="insurance-recommendation">
                  <ElectricBoltIcon
                    fontSize="inherit"
                    sx={{ marginRight: "4px", color: "#ffab03" }}
                  />
                  <span>
                    {t("add.car_security.serial_number.status.initial")}
                  </span>
                </p>
              ) : (
                <span>
                  {t("add.car_security.serial_number.status.verification")}
                </span>
              )}
            </AccordionDetails>
          </Accordion>
          <Accordion className="file-container" defaultExpanded>
            <AccordionSummary
              expandIcon={
                <img className="drop-row" src={navbarImages.downArrow} alt="" />
              }
              className="d-flex justify-content-between accordion-summary"
              id="drop_arrow"
            >
              {setStatusIcon("circulationCard")}
              <div className="title-container">
                <p className="option-title">
                  {t("add.car_security.circulation_card")}
                </p>
                {setStatusText("circulationCard")}
              </div>
            </AccordionSummary>
            <AccordionDetails className="drop-options" id="card_file">
              <div className="document-generic-input">
                <p className="extra-info-p">
                  {t("add.car_security.circulation_card_revision_status")}
                </p>
                {!isLoading && (
                  <>
                    <UploadDropZone
                      allow="file,image"
                      width="100%"
                      fileDetails={{
                        url: circulationCardFile?.url || "",
                        type: circulationCardFile?.mime || "",
                      }}
                      path={{
                        fileName: "tarjeta_de_circulación",
                        folderName: `/${uploadioEnv}/user-${getUserId()}/car-${carId}`,
                      }}
                      showRemove
                      onUpload={(url, mime) => {
                        setCirculationCardFile({ url, mime });
                        setFieldValue(
                          "docVehicleLicense",
                          ValidateInfo.PENDING
                        );
                      }}
                      onDelete={() => {
                        setCirculationCardFile(undefined);
                        setFieldValue(
                          "docVehicleLicense",
                          ValidateInfo.NOT_UPLOADED
                        );
                      }}
                    />

                    <p className="upload-later">
                      <ElectricBoltIcon
                        fontSize="inherit"
                        sx={{ marginRight: "4px", color: "#ffab03" }}
                      />
                      <span>
                        {t("add.car_security.serial_number.status.initial")}
                      </span>
                    </p>
                  </>
                )}
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion className="file-container" defaultExpanded>
            <AccordionSummary
              expandIcon={
                <img className="drop-row" src={navbarImages.downArrow} alt="" />
              }
              className="d-flex justify-content-between accordion-summary"
              id="drop_arrow"
            >
              {setStatusIcon("insurance")}
              <div className="title-container">
                <p className="option-title">
                  {t("add.car_security.insurance_document")}
                </p>
                {setStatusText("insurance")}
              </div>
            </AccordionSummary>
            <AccordionDetails className="drop-options" id="card_file">
              <div className="document-generic-input">
                <p>{t("add.car_security.add_insurance")}</p>
                {!isLoading && (
                  <>
                    <UploadDropZone
                      allow="file,image"
                      width="100%"
                      fileDetails={{
                        url: insurancePolicyFile?.url || "",
                        type: insurancePolicyFile?.mime || "",
                      }}
                      path={{
                        fileName: "poliza_de_seguro",
                        folderName: `/${uploadioEnv}/user-${getUserId()}/car-${carId}`,
                      }}
                      showRemove
                      onUpload={(url, mime) => {
                        setInsurancePolicyFile({ url, mime });
                        setFieldValue(
                          "docInsuranceCover",
                          DocInsuranceCover.PENDING
                        );
                      }}
                      onDelete={() => {
                        setInsurancePolicyFile(undefined);
                        setFieldValue(
                          "docInsuranceCover",
                          DocInsuranceCover.NOT_UPLOADED
                        );
                      }}
                    />

                    <p className="upload-later">
                      <ElectricBoltIcon
                        fontSize="inherit"
                        sx={{ marginRight: "4px", color: "#ffab03" }}
                      />
                      <span>
                        {t("add.car_security.serial_number.status.initial")}
                      </span>
                    </p>
                  </>
                )}
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion className="file-container" defaultExpanded>
            <AccordionSummary
              expandIcon={
                <img className="drop-row" src={navbarImages.downArrow} alt="" />
              }
              className="d-flex justify-content-between accordion-summary"
              id="drop_arrow"
            >
              {setStatusIcon("gps")}
              <div className="title-container">
                <p className="option-title">
                  {t("add.car_security.gps.title")}
                </p>
                {setStatusText("gps")}
              </div>
            </AccordionSummary>
            <AccordionDetails className="gps-inputs" id="card_file">
              <div className="gps-info">
                <p>{t("add.car_security.gps.must")}</p>
                <p>{t("add.car_security.gps.info")}</p>
                <p className="extra-info-p">
                  {t("add.car_security.gps.extra_info")}
                  <a href="">
                    {t("add.car_security.gps.extra_info_responsabilities")}
                  </a>
                </p>
              </div>
              <div className="generic-gps-dropdown">
                <label>{t("add.car_security.gps.has_system.title")}</label>
                <FormControl fullWidth>
                  <Select
                    labelId="sistem"
                    id="sistem"
                    className="gps-dropdown"
                    onChange={(e) => {
                      setFieldValue("hasGPS", parseInt(e.target.value));
                    }}
                    value={values.hasGPS?.toString() || ""}
                  >
                    <MenuItem value={"0"} disabled>
                      {t("add.car_security.gps.has_system.placeholder")}
                    </MenuItem>
                    <MenuItem value={"1"}>
                      {t("add.car_security.gps.has_system.no")}
                    </MenuItem>
                    <MenuItem value={"2"}>
                      {t("add.car_security.gps.has_system.yes")}
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="generic-gps-dropdown">
                <label>{t("add.car_security.gps.device.title")}</label>
                <FormControl fullWidth>
                  <Select
                    labelId="device"
                    id="device"
                    className="gps-dropdown"
                    onChange={(e) => {
                      setFieldValue("offerGPS", parseInt(e.target.value));
                    }}
                    value={values.offerGPS?.toString() || ""}
                  >
                    <MenuItem value={""} disabled>
                      {t("add.car_security.gps.has_system.placeholder")}
                    </MenuItem>
                    <MenuItem value={"0"}>
                      {t("add.car_security.gps.device.no")}
                    </MenuItem>
                    <MenuItem value={"1"}>
                      {t("add.car_security.gps.device.yes")}
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion className="file-container" defaultExpanded>
            <AccordionSummary
              className="d-flex justify-content-between accordion-summary"
              id="e_drop_arrow4"
              expandIcon={
                <img className="drop-row" src={navbarImages.downArrow} alt="" />
              }
            >
              <p className="option-title">{t("add.car_price.goals.title")}</p>
            </AccordionSummary>
            <AccordionDetails className="drop-options" id="goals">
              <div className="rules">
                <div className="generic-input">
                  <label>{t("add.car_price.goals.what_is_your_goal?")}</label>
                  <FormControl fullWidth>
                    <Select
                      labelId="goal"
                      id="goal"
                      className="dropdown-goals"
                      onChange={(e) => handleQA(e, "goal")}
                      value={goal}
                    >
                      <MenuItem value={"0"} disabled>
                        {t("add.car_price.choose_an_option")}
                      </MenuItem>
                      <MenuItem value={"1"}>
                        {t("add.car_price.goals.generate_additional_income")}
                      </MenuItem>
                      <MenuItem value={"2"}>
                        {t("add.car_price.goals.cover_car_payments")}
                      </MenuItem>
                      <MenuItem value={"3"}>
                        {t("add.car_price.goals.expand_current_business")}
                      </MenuItem>
                      <MenuItem value={"4"}>
                        {t("add.car_price.goals.primary_income_of_income")}
                      </MenuItem>
                      <MenuItem value={"5"}>
                        {t("add.car_price.goals.other")}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="generic-input">
                  <label>{t("add.car_price.how_often.title")}</label>
                  <FormControl fullWidth>
                    <Select
                      labelId="frequencyUse"
                      id="frequencyUse"
                      className="dropdown-goals"
                      label=""
                      onChange={(e) => handleQA(e, "frequencyUse")}
                      value={frequencyUse}
                    >
                      <MenuItem value={"0"} disabled>
                        {t("add.car_price.choose_an_option")}
                      </MenuItem>
                      <MenuItem value={"1"}>
                        {t("add.car_price.how_often.Never")}
                      </MenuItem>
                      <MenuItem value={"2"}>
                        {t("add.car_price.how_often.uno_two_week")}
                      </MenuItem>
                      <MenuItem value={"3"}>
                        {t("add.car_price.how_often.three_four_week")}
                      </MenuItem>
                      <MenuItem value={"4"}>
                        {t("add.car_price.how_often.fifth_six_week")}
                      </MenuItem>
                      <MenuItem value={"5"}>
                        {t("add.car_price.how_often.everyday")}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="generic-input">
                  <label>{t("add.car_price.often_share_car.title")}</label>
                  <FormControl fullWidth>
                    <Select
                      labelId="frequencyShare"
                      id="frequencyShare"
                      className="dropdown-goals"
                      onChange={(e) => handleQA(e, "frequencyShare")}
                      value={frequencyShare}
                    >
                      <MenuItem value={"0"} disabled>
                        {t("add.car_price.choose_an_option")}
                      </MenuItem>
                      <MenuItem value={"1"}>
                        {t("add.car_price.often_share_car.not_know_yet")}
                      </MenuItem>
                      <MenuItem value={"2"}>
                        {t("add.car_price.often_share_car.rarely")}
                      </MenuItem>
                      <MenuItem value={"3"}>
                        {t("add.car_price.often_share_car.few_times")}
                      </MenuItem>
                      <MenuItem value={"4"}>
                        {t("add.car_price.often_share_car.frequent")}
                      </MenuItem>
                      <MenuItem value={"5"}>
                        {t("add.car_price.often_share_car.very_frequent")}
                      </MenuItem>
                      <MenuItem value={"6"}>
                        {t("add.car_price.often_share_car.always")}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </div>
  );
};
