import reservationsImages from "../../../../assets/images/reservations";
import carImages from "../../../../assets/images/car";
import authenticationImages from "../../../../assets/images/authentication";
import { useTranslation } from "react-i18next";
import { FC, useEffect, useRef, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { getHost, updateHost } from "../../../../services/host";
import Autocomplete from "react-google-autocomplete";
import { useFormik } from "formik";
import {
  DocPolicyStatus,
  HostCProfileForm,
  HostStatus,
  TypeInsurance,
} from "../../../../models/host/types";
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import useHostCommercialInfo from "./useHostCommercialInfo";
import UploadDropZone from "../../../../components/uploadDropZone/component";
import { hostCommercialSchema } from "../../../../views/commercialSignup/form";
import { getUserId } from "../../../../services/localstorage";
import { uploadioEnv } from "../../../../services/fileUploader";

export const HostCommercialInfo = () => {
  const [commercialHostForm, setCommercialHostForm] = useState<boolean>(false);
  const { t } = useTranslation(["profile", "commercialSignup"]);
  const queryClient = useQueryClient();
  const { data: hostC, isSuccess: hostSuccess } = useQuery(["hostC"], () =>
    getHost()
  );
  const [, setInsurance] = useState<number>(0);

  const { rentPoliciesFile, setRentPoliciesFile } = useHostCommercialInfo();

  useEffect(() => {
    if (!hostSuccess) return;

    formikHostCommercial.setFieldValue("comercialName", hostC?.comercialName);
    formikHostCommercial.setFieldValue("businessName", hostC?.businessName);
    formikHostCommercial.setFieldValue("rfc", hostC?.rfc);
    formikHostCommercial.setFieldValue("legalAddress", hostC?.legalAddress);
    formikHostCommercial.setFieldValue("insurance", hostC?.insurance);
    formikHostCommercial.setFieldValue(
      "rentPoliciesStatus",
      hostC?.rentPoliciesStatus
    );
  }, [hostC]);

  const fetchHostC = async (values: HostCProfileForm) => {
    const id = getUserId();

    if (!id) return;

    const response = await updateHost(hostC?.id || 0, {
      comercialName: values.comercialName || "",
      businessName: values.businessName || "",
      rfc: values.rfc || "",
      legalAddress: values.legalAddress || "",
      insurance: values.insurance || TypeInsurance.OUR_INSURANCE,
      rentPoliciesStatus: values.rentPoliciesStatus,
    });

    if (response?.status === 204) {
      queryClient.invalidateQueries(["hostC"]);
    }
  };

  const handleInsurance = (event: SelectChangeEvent<number>) => {
    formikHostCommercial.setFieldValue("insurance", event.target.value);
    setInsurance(event.target.value as number);
  };

  const formikHostCommercial = useFormik<HostCProfileForm>({
    initialValues: {
      comercialName: "",
      businessName: "",
      rfc: "",
      legalAddress: "",
      insurance: TypeInsurance.HOST_INSURANCE,
      rentPoliciesStatus: DocPolicyStatus.NOT_UPLOADED,
    },
    validationSchema: hostCommercialSchema,
    onSubmit: fetchHostC,
  });

  return (
    <>
      {hostC && (
        <div className="anfitrion-comercial-info">
          <div className="card">
            <div className="title-status-container">
              <div className="verifying-comercial-host">
                <VerifyingHost status={hostC.status || HostStatus.PENDING} />
              </div>
              <h3 className="title">
                {t("profile:profile_boxes.host_commercial_info.title")}
              </h3>
            </div>
            <div className="dropdown-divider"></div>
            <div
              id="anfitrion_comercial_form"
              className="card-body anfitrion-container"
            >
              {commercialHostForm ? (
                <div className="profile-info-form hidden">
                  <div className="profile-generic-info col-sm-12 col-md-12 col-lg-4 name">
                    <label>
                      {t(
                        "profile:profile_boxes.host_commercial_info.commercial_name"
                      )}
                    </label>
                    <div className="input-text-large">
                      <input
                        id="comercial_name"
                        className="comercial-name"
                        type="text"
                        placeholder=""
                        value={formikHostCommercial.values.comercialName}
                        onChange={formikHostCommercial.handleChange(
                          "comercialName"
                        )}
                      />
                    </div>
                  </div>
                  <div className="profile-generic-info col-sm-12 col-md-12 col-lg-4 social-reason">
                    <label>
                      {t(
                        "profile:profile_boxes.host_commercial_info.business_name"
                      )}
                    </label>
                    <div className="input-text-large">
                      <input
                        id="business_name"
                        className="business-name"
                        type="text"
                        value={formikHostCommercial.values.businessName}
                        onChange={formikHostCommercial.handleChange(
                          "businessName"
                        )}
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="profile-generic-info col-sm-12 col-md-12 col-lg-4 rfc">
                    <label>
                      {t("profile:profile_boxes.host_commercial_info.rfc")}
                    </label>
                    <div className="input-text-large">
                      <input
                        id="rfc"
                        type="text"
                        className="rfc"
                        value={formikHostCommercial.values.rfc}
                        onChange={formikHostCommercial.handleChange("rfc")}
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="profile-generic-info col-sm-12 col-md-12 col-lg-6 mx-0 fiscal-location">
                    <label>
                      {t(
                        "profile:profile_boxes.host_commercial_info.tax_residence"
                      )}
                    </label>
                    <div className="input-text-large">
                      <Autocomplete
                        id="txtUbication"
                        className="w-100"
                        apiKey={process.env.REACT_APP_GOOGLE_MAPS}
                        defaultValue={formikHostCommercial.values.legalAddress}
                        options={{
                          componentRestrictions: { country: "mx" },
                          fields: [
                            "geometry",
                            "formatted_address",
                            "place_id",
                            "address_components",
                            "name",
                          ],
                          types: [],
                        }}
                        language="es-419"
                        onPlaceSelected={(place) =>
                          formikHostCommercial.setFieldValue(
                            "legalAddress",
                            place.formatted_address
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className="profile-generic-info col-sm-12 col-md-12 col-lg-6 mx-0 own-insurance">
                    <label>
                      {t(
                        "profile:profile_boxes.host_commercial_info.own_insurance.label"
                      )}
                    </label>
                    <FormControl fullWidth>
                      <Select
                        id="version"
                        className="dropdown"
                        label=""
                        value={formikHostCommercial.values.insurance}
                        onChange={(e) => handleInsurance(e)}
                      >
                        <MenuItem value={0}>
                          {t(
                            "profile:profile_boxes.host_commercial_info.own_insurance.no"
                          )}
                        </MenuItem>
                        <MenuItem value={1}>
                          {t(
                            "profile:profile_boxes.host_commercial_info.own_insurance.yes"
                          )}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  {formikHostCommercial.values.insurance ==
                    TypeInsurance.HOST_INSURANCE && (
                    <div
                      id="rent_policies"
                      className="profile-generic-info profile-image col-sm-12 col-md-12 col-lg-12"
                    >
                      <label>
                        {t(
                          "profile:profile_boxes.host_commercial_info.rent_policies.title"
                        )}
                      </label>
                      <div
                        id="con_politicas_renta"
                        className="document-generic-input con_politicas_de_renta"
                      >
                        <UploadDropZone
                          allow="image"
                          fileDetails={{
                            url: rentPoliciesFile?.url || "",
                            type: rentPoliciesFile?.mime || "",
                          }}
                          path={{
                            fileName: "politicas_de_renta",
                            folderName: `/${uploadioEnv}/user-${getUserId()}`,
                          }}
                          onUpload={(url, mime) => {
                            setRentPoliciesFile({ url, mime });
                            formikHostCommercial.setFieldValue(
                              "rentPoliciesStatus",
                              DocPolicyStatus.PENDING
                            );
                          }}
                          onDelete={() => {
                            setRentPoliciesFile(undefined);
                            formikHostCommercial.setFieldValue(
                              "rentPoliciesStatus",
                              DocPolicyStatus.NOT_UPLOADED
                            );
                          }}
                          showRemove
                        />
                        <Verifying
                          status={
                            hostC.rentPoliciesStatus ||
                            DocPolicyStatus.NOT_UPLOADED
                          }
                        />
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className="profile-info">
                  <div className="profile-generic-info col-sm-12 col-md-12 col-lg-4 name">
                    <label>
                      {t(
                        "profile:profile_boxes.host_commercial_info.commercial_name"
                      )}
                    </label>
                    <div className="input-text-large">
                      <p className="comercial-name">{hostC.comercialName}</p>
                    </div>
                  </div>
                  <div className="profile-generic-info col-sm-12 col-md-12 col-lg-4 social-reason">
                    <label>
                      {t(
                        "profile:profile_boxes.host_commercial_info.business_name"
                      )}
                    </label>
                    <div className="input-text-large">
                      <p className="business-name">{hostC.businessName}</p>
                    </div>
                  </div>
                  <div className="profile-generic-info col-sm-12 col-md-12 col-lg-4 rfc">
                    <label>{t("commercialSignup:RFC")}</label>
                    <div className="input-text-large">
                      <p className="rfc">{hostC.rfc}</p>
                    </div>
                  </div>
                  <div className="profile-generic-info col-sm-12 col-md-12 col-lg-5 mx-0 fiscal-location">
                    <label>{t("commercialSignup:tax_residence")}</label>
                    <div className="input-text-large">
                      <p className="legal-addr">{hostC.legalAddress}</p>
                    </div>
                  </div>
                  <div className="profile-generic-info col-sm-12 col-md-12 col-lg-5 mx-0 own-insurance">
                    <label>{t("commercialSignup:own_insurance")}</label>
                    <div className="input-text-large">
                      <p className="select-insurance">
                        {hostC.insurance == TypeInsurance.HOST_INSURANCE
                          ? t(
                              "profile:profile_boxes.host_commercial_info.own_insurance.yes"
                            )
                          : t(
                              "profile:profile_boxes.host_commercial_info.own_insurance.no"
                            )}
                      </p>
                    </div>
                  </div>
                  {formikHostCommercial.values.insurance ==
                    TypeInsurance.HOST_INSURANCE && (
                    <div
                      id="rent_policies_show"
                      className="profile-generic-info profile-image col-sm-12 col-md-12 col-lg-12"
                    >
                      <label>{t("commercialSignup:rental_policies")}</label>
                      <div
                        id="con_politicas_de_renta"
                        className="document-generic-input con_politicas_de_renta"
                      >
                        <div className="form">
                          <div id="holder5" className="holder_default">
                            <div
                              id="image_container5"
                              className="image_container"
                            >
                              <img
                                src={rentPoliciesFile?.url || ""}
                                style={{ maxHeight: 300, maxWidth: "100%" }}
                                id="politicas_renta"
                                className="image-in politicas_de_renta"
                              />
                            </div>
                            <p id="image_name5" className="image-name"></p>
                          </div>
                        </div>
                        <Verifying
                          status={
                            hostC.rentPoliciesStatus ||
                            DocPolicyStatus.NOT_UPLOADED
                          }
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
              <div className="btn-container__edit-host">
                {(hostC.status != HostStatus.VALIDATED ||
                  hostC.rentPoliciesStatus != DocPolicyStatus.VALIDATED) && (
                  <button
                    id="comercial_edit"
                    type="button"
                    name="edit"
                    className={
                      "btn" +
                      (commercialHostForm ? " btn-save save" : " btn-edit edit")
                    }
                    onClick={() => {
                      setCommercialHostForm(!commercialHostForm);
                      commercialHostForm
                        ? formikHostCommercial.handleSubmit()
                        : null;
                    }}
                  >
                    <p>
                      {commercialHostForm
                        ? t(
                            "profile:profile_boxes.host_commercial_info.save_changes"
                          )
                        : t(
                            "profile:profile_boxes.host_commercial_info.edit_info"
                          )}
                    </p>
                    <img
                      src={
                        commercialHostForm
                          ? authenticationImages.check
                          : authenticationImages.edit
                      }
                      alt=""
                    />
                  </button>
                )}
              </div>
            </div>
            {hostC.status == HostStatus.VALIDATED &&
              hostC.rentPoliciesStatus == DocPolicyStatus.VALIDATED && (
                <p className="contact-us">
                  {t(
                    "profile:profile_boxes.host_commercial_info.modify_information"
                  )}{" "}
                  <a>
                    {t(
                      "profile:profile_boxes.host_commercial_info.contact_information"
                    )}
                  </a>
                </p>
              )}
          </div>
        </div>
      )}
    </>
  );
};

const Verifying: FC<{ status: DocPolicyStatus }> = ({ status }) => {
  const { t } = useTranslation(["profile"]);

  const statusColor = useRef(
    status == DocPolicyStatus.NOT_UPLOADED
      ? "not_uploaded"
      : status == DocPolicyStatus.PENDING
      ? "verify"
      : status == DocPolicyStatus.VALIDATED
      ? "validated"
      : "refused"
  );

  const statusImage = useRef(
    status == DocPolicyStatus.NOT_UPLOADED || status == DocPolicyStatus.REFUSED
      ? carImages.cross
      : status == DocPolicyStatus.PENDING
      ? reservationsImages.warning
      : carImages.validated
  );

  const statusText = useRef(
    status == DocPolicyStatus.NOT_UPLOADED
      ? t(
          "profile:profile_boxes.host_commercial_info.rent_policies.complete_file"
        )
      : status == DocPolicyStatus.PENDING
      ? t("profile:profile_boxes.host_commercial_info.rent_policies.verifying")
      : status == DocPolicyStatus.VALIDATED
      ? t("profile:profile_boxes.host_commercial_info.rent_policies.validated")
      : t("profile:profile_boxes.host_commercial_info.rent_policies.refused")
  );

  return (
    <div className="verifying">
      <span className={statusColor.current}>
        <img src={statusImage.current} alt="" />
        {statusText.current}
      </span>
    </div>
  );
};

const VerifyingHost: FC<{ status: HostStatus }> = ({ status }) => {
  const { t } = useTranslation(["profile"]);

  const statusColor = useRef(
    status == HostStatus.PENDING
      ? "verify"
      : status == HostStatus.VALIDATED
      ? "validated"
      : "refused"
  );

  const statusImage = useRef(
    status == HostStatus.REFUSED
      ? carImages.cross
      : status == HostStatus.PENDING
      ? reservationsImages.warning
      : carImages.validated
  );

  const statusText = useRef(
    status == HostStatus.PENDING
      ? t("profile:profile_boxes.host_commercial_info.host_status.verifying")
      : status == HostStatus.VALIDATED
      ? t("profile:profile_boxes.host_commercial_info.host_status.validated")
      : t("profile:profile_boxes.host_commercial_info.host_status.refused")
  );

  return (
    <div className="verifying">
      <span className={statusColor.current}>
        <img src={statusImage.current} alt="" />
        {statusText.current}
      </span>
    </div>
  );
};
