import * as Yup from "yup";

export interface PortalPaletteForm {
  primary?: string;
  secondary?: string;
}

export const mockPortalPalette: PortalPaletteForm = {
  primary: "",
  secondary: "",
};

export const PortalPaletteSchema: Yup.SchemaOf<PortalPaletteForm> =
  Yup.object().shape({
    primary: Yup.string(),
    secondary: Yup.string(),
  });
