import { FC, useContext, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import carImage from "../../../assets/images/car";
import { CancelIncome } from "../../../components/modals/cancelIncome/component";
import { CancelRequest } from "../../../components/modals/cancelRequest/component";
import { TripStatus } from "../../../models/trip/types";
import { TripContext } from "../../../views/driverReservation/show/component";
import { getPolicyUrl } from "../../../services/insurance";
import dayjs from "dayjs";
import { getDaysFromTwoDates } from "../../helpers/dateHandler";
import { Link } from "react-router-dom";
import { encryptId } from "../../helpers/encrypt";
import { useQuery } from "react-query";
import { InsuranceType } from "../../../models/insurance/types";

export const Contracts = () => {
  const { t, trip } = useContext(TripContext);

  const { data: policyUrl, isLoading } = useQuery(
    ["policyUrl", trip],
    () => getPolicyUrl(trip?.id),
    {
      enabled:
        !!trip &&
        trip.insuranceCompany === InsuranceType.QUALITAS &&
        trip.policyResult != "",
    }
  );

  if (!trip) {
    return <></>;
  }

  const activeDocs =
    trip.status !== TripStatus.CANCEL_RESERVATION_ANFITRION &&
    trip.status !== TripStatus.CANCEL_RESERVATION_BALETY &&
    trip.status !== TripStatus.CANCEL_RESERVATION_HUESPED;

  return (
    <div className="terms">
      <p className="terms-title">{t("show.trip_docs")}</p>
      {activeDocs && (
        <div className="check-list-container">
          <a
            target="_blank"
            href={
              "https://upcdn.io/12a1xrm/raw/balety.com/documents/checklist-de-renta.pdf"
            }
          >
            {t("show.download_checklist")}
          </a>
          <p>{t("show.reminder")}</p>
        </div>
      )}
      <div className="text-container">
        <a
          target="_blank"
          data-testid="check-lease-link"
          href={
            "https://upcdn.io/12a1xrm/raw/balety.com/documents/contrato-de-arrendamiento.pdf"
          }
        >
          {t("show.check_lease")}
        </a>
      </div>
      <div className="text-container">
        <a
          target="_blank"
          data-testid="check-terms-and-conditions-link"
          href={
            "https://upcdn.io/12a1xrm/raw/balety.com/documents/terminos-y-condiciones-de-uso.pdf"
          }
        >
          {t("show.check_terms_and_conditions")}
        </a>
      </div>

      {trip.status !== TripStatus.SEND_REQUEST_RESERVATION && activeDocs && (
        <div className="text-container">
          {policyUrl ? (
            <a target="_blank" data-testid="check-lease-link" href={policyUrl}>
              {t("show.check_insurance_policy")}
            </a>
          ) : (
            <p>{t("show.loading_insurance_policy")}</p>
          )}
        </div>
      )}

      {trip.status !== TripStatus.SEND_REQUEST_RESERVATION &&
        activeDocs &&
        localStorage.getItem("id") !== trip.hostId.toString() && (
          <div className="text-container">
            <a
              target="_blank"
              data-testid="check-lease-link"
              href={
                "https://upcdn.io/12a1xrm/raw/balety.com/documents/contrato-de-arrendamiento.pdf"
              }
            >
              {t("show.check_payment_receipt")}
            </a>
          </div>
        )}
    </div>
  );
};

export const ActiveActions: FC<{ finished?: boolean }> = ({
  finished = false,
}) => {
  const { t, trip } = useContext(TripContext);

  return (
    <>
      <div className="btn-active col-sm-12 col-md-12">
        {!finished && (
          <p className="last-dates timeLeft">
            {t("show.start_dates") +
              getDaysFromTwoDates(
                dayjs().format("YYYY-MM-DD"),
                dayjs(trip?.returnDate || "", "DD-MM-YYYY").format("YYYY-MM-DD")
              ) +
              t("show.last_dates")}
          </p>
        )}
        <div className="help">
          <a
            type="button"
            className="btn btn-help"
            target="_blank"
            href="https://ayuda.balety.com"
          >
            {t("show.help")}
          </a>
          <p className="txt-help">{t("show.issues")}</p>
        </div>
      </div>
    </>
  );
};

export const DepositAmountAlert = () => {
  const { t } = useContext(TripContext);

  return (
    <div className="warning  col-sm-12 col-md-12">
      <button type="button" className="btn alert-triangle">
        <img src={carImage.alertTriangle} alt="" />
      </button>
      <div className="text-container">
        <p className="depositAmount">
          <Trans>{t("show.deposit_amount_alert")}</Trans>
        </p>
      </div>
    </div>
  );
};

export const CancelActions = () => {
  const { t, trip } = useContext(TripContext);

  const status =
    trip?.status === TripStatus.CANCEL_RESERVATION_ANFITRION
      ? t("index.host")
      : trip?.status === TripStatus.CANCEL_RESERVATION_HUESPED
      ? t("index.driver")
      : t("index.romio");

  return (
    <div className="btn-cancel col-sm-12 col-md-12">
      <button type="button" className="btn btn-red">
        {t("show.cancel_actions.canceled_by")}
        {status}
      </button>
    </div>
  );
};

export const PendingActions = () => {
  const { t } = useContext(TripContext);
  const [isCancelRequestOpen, setIsCancelRequestOpen] =
    useState<boolean>(false);
  return (
    <>
      <div className="btn-pending col-sm-12 col-md-12">
        <button
          type="button"
          className="btn btn-red"
          onClick={() => setIsCancelRequestOpen(true)}
        >
          {t("show.cancel_request")}
        </button>
      </div>
      <CancelRequest
        isOpen={isCancelRequestOpen}
        setOpen={() => setIsCancelRequestOpen(false)}
      />
    </>
  );
};

export const NextActions = () => {
  const { t, trip } = useContext(TripContext);
  const [isCancelIncomeOpen, setIsCancelIncomeOpen] = useState<boolean>(false);
  return (
    <>
      <div className="btn-next col-sm-12 col-md-12">
        <p className="last-dates timeLeft">
          {t("show.start_dates") +
            getDaysFromTwoDates(
              dayjs().format("YYYY-MM-DD"),
              dayjs(trip?.pickUpDate || "", "DD-MM-YYYY").format("YYYY-MM-DD")
            ) +
            t("show.start_dates_extra")}
        </p>
        <button
          type="button"
          className="btn btn-red"
          onClick={() => setIsCancelIncomeOpen(true)}
        >
          {t("show.cancel_rent")}
        </button>
      </div>
      <CancelIncome
        isOpen={isCancelIncomeOpen}
        setOpen={() => setIsCancelIncomeOpen(false)}
      />
    </>
  );
};

export const FinishActions = () => {
  const { t } = useTranslation(["driverReservation", "routes"]);
  const { trip } = useContext(TripContext);

  return (
    <div className="btn-finish col-sm-12 col-md-12">
      <Link
        type="button"
        className="btn btn-orange"
        target="_blank"
        to={{
          pathname:
            "/" +
            t("details", { ns: "routes" }) +
            "/" +
            encryptId(trip?.carId.toString() || ""),
        }}
      >
        {t("show.rent_again")}
      </Link>
    </div>
  );
};
