import { useTranslation } from "react-i18next";
import navbarImages from "../../assets/images/navbar/index";
import "./Portal.scss";
import { useWindowSize } from "../../hooks/windowSize";
import portalImages from "../../assets/images/portal";
import { useContext, useEffect, useState } from "react";
import { ColorPicker } from "antd";
import { DomainContext } from "../../providers/domainProvider";
import { PortalPaletteForm, PortalPaletteSchema } from "./form";
import { useFormik } from "formik";
import UploadDropZone from "../../components/uploadDropZone/component";
import { getFileDetailsFromPath } from "../../services/fileUploader";
import { updateHostSettings } from "../../services/hostSettings";
import { useQueryClient } from "react-query";
import { Box } from "@mui/material";

export const Portal = () => {
  const { settings, domain } = useContext(DomainContext);
  const { t } = useTranslation(["portal", "routes"]);
  const windowSize = useWindowSize();
  const [logo, setLogo] = useState<{ url: string; mime: string } | undefined>();
  const [logoIcon, setLogoIcon] = useState<
    { url: string; mime: string } | undefined
  >();
  const queryClient = useQueryClient();

  const fetchPortalPalette = async (value: PortalPaletteForm) => {
    await updateHostSettings(value);
    await queryClient.invalidateQueries(["hostComercialSettings"]);
  };

  const formikPortalPalette = useFormik<PortalPaletteForm>({
    initialValues: {
      primary: "",
      secondary: "",
    },
    validationSchema: PortalPaletteSchema,
    onSubmit: fetchPortalPalette,
  });

  useEffect(() => {
    if (settings) {
      getFileDetails(settings.name);
      formikPortalPalette.setValues({
        primary: settings.palette.primary,
        secondary: settings.palette.secondary,
      });
    }
  }, [settings]);

  const getFileDetails = async (name: string) => {
    await getFileDetailsFromPath(`/SaaS/${name}/${name}`, setLogo);
    await getFileDetailsFromPath(`/SaaS/${name}/${name}Icon`, setLogoIcon);
  };

  return (
    <article className="portal-container">
      <div className="portal__join-container">
        <div className="portal__title-container">
          <div className="portal__route">
            <p className="portal__route__first">{t("portal.sell")}</p>
            <img
              src={navbarImages.arrowRight}
              className="portal__route__arrow"
            />
            <p className="portal__route__second">{t("portal.my_portal")}</p>
          </div>
          <h1 data-testid="my-cars-title" className="portal__title">
            {t("portal.my_portal")}
          </h1>
          <p className="portal__subtitle">{t("portal.personalize")}</p>
        </div>
      </div>

      <div className="portal-customize__information">
        <div className="portal-customize__information__title">
          <h2>{t("portal.customize.title")}</h2>
          <p>{t("portal.customize.subtitle")}</p>
        </div>
        <div className="portal-customize__basic-settings">
          <div className="portal-customize__basic-settings-title">
            <h3 className="basic-settings-container-title">
              {t("portal.customize.information")}
            </h3>
          </div>
          <div className="portal-customize__basic-settings-container">
            <div className="portal-customize__basic-settings-assets">
              <div className="basic-settings-assets__title">
                <p>{t("portal.customize.general")}</p>
                {windowSize <= 991 && <a>{t("portal.customize.preview")}</a>}
              </div>
              <div className="basic-settings-assets__color-picker-container">
                <div className="color-picker-container">
                  <UploadDropZone
                    allow="file,image"
                    fileDetails={{
                      url: logo?.url || "",
                      type: logo?.mime || "",
                    }}
                    className="logo-input"
                    showRemove
                    path={{
                      fileName: settings.name,
                      folderName: `/SaaS/${settings.name}`,
                    }}
                    onUpload={(url, mime) => setLogo({ url, mime })}
                    onDelete={() => setLogo({ url: "", mime: "" })}
                  />
                  <div className="color-picker-text">
                    <p>{t("portal.customize.profile")}</p>
                    <span>{t("portal.customize.logo")}</span>
                  </div>
                </div>
                <div className="color-picker-container">
                  <UploadDropZone
                    allow="file,image"
                    fileDetails={{
                      url: logoIcon?.url || "",
                      type: logoIcon?.mime || "",
                    }}
                    showRemove
                    className="logo-icon-input"
                    path={{
                      fileName: `${settings.name}Icon`,
                      folderName: `/SaaS/${settings.name}`,
                    }}
                    onUpload={(url, mime) => setLogoIcon({ url, mime })}
                    onDelete={() => setLogoIcon({ url: "", mime: "" })}
                  />
                  <div className="color-picker-text">
                    <p>{t("portal.customize.mobile")}</p>
                    <span>{t("portal.customize.logo_mobile")}</span>
                  </div>
                </div>
                <div className="color-picker-container">
                  <ColorPicker
                    value={formikPortalPalette.values.primary}
                    className="color-picker"
                    onChange={(color) => {
                      formikPortalPalette.setFieldValue(
                        "primary",
                        color.toHexString()
                      );
                      formikPortalPalette.handleSubmit();
                    }}
                  />
                  <div className="color-picker-text">
                    <p>{formikPortalPalette.values.primary}</p>
                    <span>{t("portal.customize.principal_color")}</span>
                  </div>
                </div>
                <div className="color-picker-container">
                  <ColorPicker
                    value={formikPortalPalette.values.secondary}
                    className="color-picker"
                    onChange={(color) => {
                      formikPortalPalette.setFieldValue(
                        "secondary",
                        color.toHexString()
                      );
                      formikPortalPalette.handleSubmit();
                    }}
                  />
                  <div className="color-picker-text">
                    <p>{formikPortalPalette.values.secondary}</p>
                    <span>{t("portal.customize.secondary_color")}</span>
                  </div>
                </div>
              </div>
            </div>
            {windowSize > 991 && (
              <div className="portal-customize__basic-settings-preview">
                <div className="basic-settings-preview__title">
                  <p>{t("portal.customize.preview")}</p>
                  <span>{t("portal.customize.visualize")}</span>
                </div>
                <img
                  src={portalImages.portalPreview}
                  alt=""
                  className="portal-preview"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </article>
  );
};
