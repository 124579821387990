import { useQuery } from "react-query";
import { getTransmission } from "../../views/car/index/types";
import carImagesRef from "../../assets/images/a_cars/index";
import { getFileDetailsIfExist } from "../../services/fileUploader";
import { uploadioEnv } from "../../services/fileUploader";
import { Car, CarStatus, IsEcofriendly } from "../../models/car/types";
import { useTranslation } from "react-i18next";
import { getHostExistByUserId } from "../../services/host";
import { useRef, useEffect } from "react";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import { Popover, Divider, Skeleton } from "@mui/material";
import ShieldOutlinedIcon from "@mui/icons-material/ShieldOutlined";
import { getInsuranceByCarId } from "../../services/insurance";
import { useState } from "react";
import { formatPrice, formatDate } from "./utils";
import { resultParams } from "../../views/results/types";
import { createSearchParams, Link } from "react-router-dom";
import { encryptId } from "../../lib/helpers/encrypt";
import carImagesIndex from "../../assets/images/car";

const CarDefaultResultsImage = ({ car }: { car: Car }) => {
  const { data: carImages, isLoading } = useQuery(["image", car.id], () =>
    getFileDetailsIfExist(
      `/${uploadioEnv}/user-${car.userId}/car-${car.id}/car_photo_1`,
      `/${uploadioEnv}/user-${car.userId}/car-${car.id}`
    )
  );

  return (
    <div className="car-image">
      {isLoading ? (
        <Skeleton variant="rectangular" className="skeleton" />
      ) : carImages?.fileUrl ? (
        <img
          className="car"
          style={{
            opacity:
              car.status === CarStatus.APPROVED ||
              car.status === CarStatus.PENDING
                ? "1"
                : "0.6",
          }}
          alt="car image"
          src={
            carImages?.fileUrl.replace("raw", "thumbnail-my-cars") +
            `?v=${carImages.etag}`
          }
        />
      ) : (
        <div className="default-car">
          <img
            className="empty-image"
            style={{
              opacity:
                car.status === CarStatus.APPROVED ||
                car.status === CarStatus.PENDING
                  ? "1"
                  : "0.6",
            }}
            alt="car no image"
            src={carImagesIndex.defaultCar}
          />
        </div>
      )}
    </div>
  );
};

const CarResultCard = ({ car, params }: { car: Car; params: resultParams }) => {
  const { t } = useTranslation(["results"]);
  const { data: hostCommercial } = useQuery(
    ["isHostCommercial", car.userId],
    () => getHostExistByUserId(car?.userId.toString()),
    {
      enabled: !!car,
    }
  );
  const { data: insurance } = useQuery(
    ["InsuranceCar", car?.id],
    () => getInsuranceByCarId(car?.id),
    {
      enabled: !!car,
    }
  );
  const transmissionValue = useRef(getTransmission(car.transmission));
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [days, setDays] = useState<number>(1);
  const [totalPrice, setTotalPrice] = useState<number>(0);

  const handlePopoverOpen = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };
  const { dateEnd, dateInitial, destinationId, hourEnd, hourInitial, state } =
    params;

  // Number of days according to selected dates
  useEffect(() => {
    if (dateEnd && dateInitial) {
      const endDateValidFormat = formatDate(dateEnd);
      const startDateValidFormat = formatDate(dateInitial);
      const difference =
        (new Date(endDateValidFormat).getTime() -
          new Date(startDateValidFormat).getTime()) /
        (1000 * 60 * 60 * 24);
      setDays(difference);
    }
  }, [params]);

  // Total price
  useEffect(() => {
    const carPrice = parseFloat(car.price) * days;
    const insurancePrice = parseFloat(insurance?.customerPrice || "0") * days;
    setTotalPrice(carPrice + insurancePrice);
  }, [insurance, car, days]);

  return (
    <div className="card show-card">
      <Link
        style={{ cursor: "pointer", color: "#000", textDecoration: "none" }}
        target="_blank"
        to={{
          pathname:
            "/" +
            t("details", { ns: "routes" }) +
            "/" +
            encryptId(car.id.toString()),
          search: `?${createSearchParams({
            destinationId: destinationId?.toString() || "",
            dateInitial,
            dateEnd,
            hourInitial,
            hourEnd,
            state,
          })}`,
        }}
      >
        {/* FOR LATER: car number of trips and heart to make favorite */}
        {/* <span className="trips-tag d-flex align-items-center justify-content-center">
        {`4000 ${t("card.trips")}`}
      </span>
      <img className="heart" src={h_homepageImages.emptyHeart} alt="" /> */}
        <CarDefaultResultsImage car={car} />

        <div className="card-body-title">
          <div className="card-header-div d-flex align-items-center">
            <p className="card-title">
              {`${car.brand} ${car.model} ${car.year}`}
            </p>
            {car.isEcoFriendly !== IsEcofriendly.NEGATIVE && (
              <img
                src={carImagesRef.ecoType}
                alt="ecological icon"
                className="ecological-image"
              />
            )}
          </div>
          <div className="d-flex align-items-center">
            {/* FOR LATER: car rating */}
            {/* <p className="card-text">
            3 <img src={h_homepageImages.unStar} />
          </p> */}
            {hostCommercial && (
              <p className="card-text">{t("card.host-commercial")}</p>
            )}
          </div>
          <hr />
        </div>
      </Link>
      <div className="card-body d-flex justify-content-between">
        <div className="car-data-box">
          {insurance && (
            <div className="car-insurance-data d-flex">
              <p>
                <ShieldOutlinedIcon />
                {t("card.insurance-label")} &#43;
              </p>
              <p>
                &#36;{insurance?.customerPrice} &#8725; {t("card.day")}
              </p>
            </div>
          )}
          <div className="d-flex">
            <div className="d-flex car-data-item">
              <PermIdentityOutlinedIcon sx={{ marginRight: "4px" }} />
              <p>{car.numSeats}</p>
            </div>
            <div className="d-flex car-data-item">
              <img
                src={carImagesRef?.bag}
                alt="bag icon"
                style={{
                  width: "1em",
                  height: "1em",
                  fontSize: "1em",
                  marginRight: "6px",
                }}
              />
              <p>{car.numBags}</p>
            </div>
            <div className="d-flex car-data-item">
              <img
                src={carImagesRef?.transmission}
                alt="transmission icon"
                style={{
                  width: "1em",
                  height: "1em",
                  fontSize: "1em",
                  marginRight: "6px",
                }}
              />
              <p>{transmissionValue.current}</p>
            </div>
          </div>
        </div>
        <div
          className="card-price-box"
          aria-haspopup="true"
          onClick={handlePopoverOpen}
          aria-owns={anchorEl ? "mouse-over-popover" : undefined}
        >
          <p className="card-price">${car.price}/día</p>
          <p>
            {formatPrice(totalPrice + 100 || 0)}
            {t("card.total")}
          </p>
        </div>
      </div>
      <Popover
        id="mouse-over-popover"
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          sx: {
            borderRadius: "8px",
            padding: "18px 14px",
            boxShadow: "0px 8px 25px rgba(209, 217, 230, 0.8)",
          },
        }}
      >
        <div className="price-popover-item">
          <p>{t("card.car")}</p>
          <p className="d-flex justify-content-between">
            <span>
              {formatPrice(parseFloat(car.price)) +
                " / " +
                t("card.day") +
                ` (x${days})`}
            </span>
            <span>{formatPrice(parseFloat(car.price) * days)}</span>
          </p>
        </div>
        <div className="price-popover-item">
          <p>{t("card.insurance-label")}</p>
          <p className="d-flex justify-content-between">
            <span>
              {formatPrice(parseFloat(insurance?.customerPrice || "0")) +
                " / " +
                t("card.day") +
                ` (x${days})`}
            </span>
            <span>
              {formatPrice(parseFloat(insurance?.customerPrice || "0") * days)}
            </span>
          </p>
        </div>
        <div className="price-popover-item">
          <p>{t("card.service_label")}</p>
          <p className="d-flex justify-content-between">
            <span>
              {formatPrice(100)} {t("card.service_charge")}
            </span>
            <span>{formatPrice(100)}</span>
          </p>
          <Divider sx={{ backgroundColor: "#D8DCE6" }} />
        </div>
        <div className="price-popover-box d-flex justify-content-between">
          <p>{t("card.total_price")}</p>
          <p>
            {formatPrice(totalPrice + 100 || 0)}
            {t("card.currency")}
          </p>
        </div>
      </Popover>
    </div>
  );
};

export default CarResultCard;
