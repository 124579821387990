import { ChannelList, ChannelSettings, Channel } from "@sendbird/uikit-react";
import Header from "@sendbird/uikit-react/ui/Header";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { useEffect, useState } from "react";
import { GroupChannel, Member } from "@sendbird/chat/groupChannel";
import authenticationImages from "../../assets/images/authentication";
import "./Chat.scss";
import chatImages from "../../assets/images/chat";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "../../hooks/windowSize";
import CloseIcon from "@mui/icons-material/Close";
import { useQuery } from "react-query";
import { getCar } from "../../services/car";
import {
  getFileDetailsIfExist,
  uploadioEnv,
} from "../../services/fileUploader";
import { getUser } from "../../services/user";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { encryptId } from "../../lib/helpers/encrypt";

const PANELS = {
  CHANNEL_LIST: "CHANNEL_LIST",
  CHANNEL: "CHANNEL",
  CHANNEL_SETTINGS: "CHANNEL_SETTINGS",
  MESSAGE_SEARCH: "MESSAGE_SEARCH",
};

interface PropsMember extends Member {
  nickname: string;
  metaData: { Id: string; Email: string };
}

export const Chat = () => {
  const { t } = useTranslation(["chat", "routes"]);
  const location = useLocation();
  const isHostRoute =
    location.pathname === "/" + t("routes:hosts") + "/" + t("routes:chat");
  const [panel, setPanel] = useState<string>(PANELS.CHANNEL_LIST);
  const [currentChannel, setCurrentChannel] = useState<GroupChannel | null>(
    null
  );
  const [carId, setCarId] = useState<string | undefined>();
  const { data: currentUser } = useQuery(["profile"], getUser);
  const [user, setUser] = useState<PropsMember | undefined>();
  const { data: car } = useQuery(["car", carId], () => getCar(carId), {
    enabled: !!carId,
  });
  const { data: carImages } = useQuery(
    ["image", car?.id],
    () =>
      getFileDetailsIfExist(
        `/${uploadioEnv}/user-${car?.userId}/car-${car?.id}/car_photo_1`,
        `/${uploadioEnv}/user-${car?.userId}/car-${car?.id}`
      ),
    { enabled: !!car }
  );
  const { data: profileImage } = useQuery(
    ["profileImage", user?.metaData.Id],
    () =>
      getFileDetailsIfExist(
        `/${uploadioEnv}/user-${user?.metaData.Id}/foto_perfil`,
        `/${uploadioEnv}/user-${user?.metaData.Id}`
      ),
    { enabled: !!user }
  );
  const windowSize = useWindowSize();
  const isMobile = windowSize < 900;
  const currentChannelUrl = currentChannel ? currentChannel.url : "";
  const [showSettings, setShowSettings] = useState<boolean>(false);
  const channelChatDiv = document.getElementsByClassName(
    "channel-chat"
  )[0] as HTMLElement;

  const renderSettingsBar = () => {
    channelChatDiv.style.width = "52%";
    channelChatDiv.style.cssFloat = "left";
  };
  const hideSettingsBar = () => {
    channelChatDiv.style.width = "76%";
    channelChatDiv.style.cssFloat = "right";
  };

  useEffect(() => {
    if (currentChannel && currentUser) {
      const metaData = currentChannel.cachedMetaData as {
        CarId: string;
        TripId: string[];
      };
      setCarId(metaData.CarId);
      const user = currentChannel.members.find((user) => {
        const userActive = user.metaData as {
          Id: string;
          Email: string;
        };

        return currentUser.id.toString() !== userActive.Id;
      });

      setUser(user as PropsMember);
    }
  }, [currentChannel, currentUser]);

  if (isMobile) {
    return (
      <div className="mobile-layout">
        {panel === PANELS.CHANNEL_LIST && (
          <ChannelList
            renderHeader={() => (
              <Header renderLeft={() => <Header.Title title={"Mensajes"} />} />
            )}
            onChannelSelect={(channel) => {
              setCurrentChannel(channel);
              channel && setPanel(PANELS.CHANNEL);
            }}
            disableAutoSelect
          />
        )}
        {panel === PANELS.CHANNEL && (
          <Channel
            channelUrl={currentChannelUrl}
            onBackClick={() => {
              setPanel(PANELS.CHANNEL_LIST);
            }}
            onChatHeaderActionClick={() => {
              setPanel(PANELS.CHANNEL_SETTINGS);
            }}
            renderPlaceholderInvalid={() => (
              <div className="empty-chats">
                <p className="information">{t("chat.empty_chats")}</p>
                <img src={chatImages.emptyChat} alt="" />
              </div>
            )}
            renderChannelHeader={() => (
              <div className="channel-header">
                <ArrowBackOutlinedIcon
                  onClick={() => {
                    setPanel(PANELS.CHANNEL_LIST);
                  }}
                />
                <div className="channel-header-information">
                  <h2 className="nickname">{user?.nickname}</h2>
                  <p className="car">{currentChannel?.name}</p>
                </div>
                <InfoOutlinedIcon
                  onClick={() => {
                    setShowSettings((prev) => !prev);
                    setPanel(PANELS.CHANNEL_SETTINGS);
                  }}
                />
              </div>
            )}
          />
        )}
        {panel === PANELS.CHANNEL_SETTINGS && (
          <ChannelSettings
            channelUrl={currentChannelUrl}
            renderHeader={() => {
              return (
                <div className="sendbird-channel-settings__header">
                  <button
                    className="btn btn-close-settings"
                    onClick={() => {
                      setPanel(PANELS.CHANNEL);
                      setShowSettings((prev) => !prev);
                    }}
                  >
                    <CloseIcon />
                  </button>
                  <h3 className="title">{t("chat.trip_data")}</h3>
                </div>
              );
            }}
            renderChannelProfile={() => (
              <div className="sendbird-channel-settings__profile">
                <p className="title">{t("chat.about_host")}</p>
                <div className="profile-settings-container">
                  <img
                    src={
                      profileImage
                        ? profileImage.fileUrl.replace(
                            "raw",
                            "thumbnail-list"
                          ) + `?v=${profileImage.etag}`
                        : authenticationImages.profileDefault
                    }
                    alt=""
                  />
                  <div className="profile-data">
                    <p className="name">{user?.nickname}</p>
                    <span className="type">
                      {isHostRoute
                        ? t("chat.driver_verified")
                        : t("chat.host_verified")}
                    </span>
                    <a href="" className="profile">
                      {isHostRoute
                        ? t("chat.profile_driver")
                        : t("chat.profile")}
                    </a>
                  </div>
                </div>
              </div>
            )}
            renderModerationPanel={() => (
              <div className="sendbird-channel-settings__car_data">
                <p className="title">{t("chat.car_data")}</p>
                <div className="car-settings-container">
                  <img
                    src={
                      carImages?.fileUrl.replace("raw", "thumbnail-list") +
                      `?v=${carImages?.etag}`
                    }
                    alt=""
                  />
                  <div className="car-information">
                    <p>{currentChannel?.name}</p>
                    {isHostRoute ? (
                      <a href="">{t("chat.check_listed")}</a>
                    ) : (
                      <Link
                        to={{
                          pathname:
                            "/" +
                            t("details", { ns: "routes" }) +
                            "/" +
                            encryptId(car?.id.toString() || "0"),
                        }}
                      >
                        {t("chat.rent_again")}
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            )}
            renderLeaveChannel={() => (
              <div className="sendbird-channel-settings__footer">
                <p className="issues">{t("chat.issues")}</p>
                <a href="" className="help">
                  {t("chat.help")}
                </a>
              </div>
            )}
          />
        )}
      </div>
    );
  }

  return (
    <div className="channel-wrap">
      <div className="channel-list">
        <ChannelList
          renderHeader={() => (
            <Header renderLeft={() => <Header.Title title={"Mensajes"} />} />
          )}
          onChannelSelect={(channel) => {
            setCurrentChannel(channel);
          }}
          disableAutoSelect
        />
      </div>
      <div className="channel-chat">
        <Channel
          channelUrl={currentChannelUrl}
          onChatHeaderActionClick={() => {
            setShowSettings((prev) => !prev);
            renderSettingsBar();
          }}
          renderPlaceholderInvalid={() => (
            <div className="empty-chats">
              <p className="information">{t("chat.empty_chats")}</p>
              <img src={chatImages.emptyChat} alt="" />
            </div>
          )}
          renderChannelHeader={() => (
            <div className="channel-header">
              <div className="channel-header-information">
                <h2 className="nickname">{user?.nickname}</h2>
                <p className="car">{currentChannel?.name}</p>
              </div>
              <button
                className="btn btn-details"
                onClick={() => {
                  setShowSettings((prev) => !prev);
                  renderSettingsBar();
                }}
              >
                {t("chat.details")}
              </button>
            </div>
          )}
        />
      </div>
      {showSettings && (
        <div className="channel-settings">
          <ChannelSettings
            channelUrl={currentChannelUrl}
            onCloseClick={() => {
              setShowSettings(false);
              hideSettingsBar();
            }}
            renderHeader={() => {
              return (
                <div className="sendbird-channel-settings__header">
                  <button
                    className="btn btn-close-settings"
                    onClick={() => setShowSettings((prev) => !prev)}
                  >
                    <CloseIcon />
                  </button>
                  <h3 className="title">{t("chat.trip_data")}</h3>
                </div>
              );
            }}
            renderChannelProfile={() => (
              <div className="sendbird-channel-settings__profile">
                <p className="title">{t("chat.about_host")}</p>
                <div className="profile-settings-container">
                  <img
                    src={
                      profileImage
                        ? profileImage.fileUrl.replace(
                            "raw",
                            "thumbnail-list"
                          ) + `?v=${profileImage.etag}`
                        : authenticationImages.profileDefault
                    }
                    alt=""
                  />
                  <div className="profile-data">
                    <p className="name">{user?.nickname}</p>
                    <span className="type">
                      {isHostRoute
                        ? t("chat.driver_verified")
                        : t("chat.host_verified")}
                    </span>
                    <a href="" className="profile">
                      {isHostRoute
                        ? t("chat.profile_driver")
                        : t("chat.profile")}
                    </a>
                  </div>
                </div>
              </div>
            )}
            renderModerationPanel={() => (
              <div className="sendbird-channel-settings__car_data">
                <p className="title">{t("chat.car_data")}</p>
                <div className="car-settings-container">
                  <img
                    src={
                      carImages?.fileUrl.replace("raw", "thumbnail-list") +
                      `?v=${carImages?.etag}`
                    }
                    alt=""
                  />
                  <div className="car-information">
                    <p>{currentChannel?.name}</p>
                    {isHostRoute ? (
                      <a href="">{t("chat.check_listed")}</a>
                    ) : (
                      <Link
                        to={{
                          pathname:
                            "/" +
                            t("details", { ns: "routes" }) +
                            "/" +
                            encryptId(car?.id.toString() || "0"),
                        }}
                      >
                        {t("chat.rent_again")}
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            )}
            renderLeaveChannel={() => (
              <div className="sendbird-channel-settings__footer">
                <p className="issues">{t("chat.issues")}</p>
                <a href="" className="help">
                  {t("chat.help")}
                </a>
              </div>
            )}
          />
        </div>
      )}
    </div>
  );
};
