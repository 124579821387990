import { FC } from "react";
import "./ClientCard.scss";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { getUserByID } from "../../../../services/user";
import {
  getFileDetailsIfExist,
  uploadioEnv,
} from "../../../../services/fileUploader";
import { Skeleton } from "@mui/material";
import authenticationImages from "../../../../assets/images/authentication";
import reservationImages from "../../../../assets/images/reservations";
import { TrustedUser } from "../../../../models/user/types";
import { Client } from "../../../../models/client/types";

export const ClientCard: FC<{
  className?: string;
  userId: number;
}> = ({ className, userId }) => {
  const { t } = useTranslation(["car"]);

  const { data: userData, isLoading } = useQuery(
    ["user", userId],
    () => getUserByID(userId),
    {
      enabled: !!userId,
    }
  );

  const { data: hostImage } = useQuery(
    ["hostImage", userId],
    () =>
      getFileDetailsIfExist(
        `/${uploadioEnv}/user-${userId}/foto_perfil`,
        `/${uploadioEnv}/user-${userId}`
      ),
    {
      enabled: !!userId,
    }
  );

  if (isLoading) {
    return (
      <Skeleton
        variant="rounded"
        className={`trip-client-card ${className}`}
        height={70}
      />
    );
  }

  return (
    <div className={`trip-client-card ${className}`}>
      <img
        src={
          hostImage
            ? hostImage.fileUrl.replace("raw", "profile-picture") +
              `?v=${hostImage.etag}`
            : authenticationImages.profileDefault
        }
        className="client-img"
        alt=""
      />
      <div className="client-info">
        <h3 className="name" id="nombre">
          {userData?.name + " " + userData?.surName}
        </h3>
        {userData?.trustedUser === TrustedUser.TRUSTED && (
          <div className="host-indicator-container">
            <span className="hostC-indicator">
              {t("show.car_form.trusted_user")}{" "}
            </span>
            <img
              src={reservationImages.checkVerified}
              className="check-host"
              alt=""
            />
          </div>
        )}
      </div>
    </div>
  );
};
