import { DateRange } from "@mui/x-date-pickers-pro";
import dayjs, { Dayjs } from "dayjs";
import { EditDateBlock } from "../components/carTools/editCarPrice/types";
import { InsuranceType } from "../../models/insurance/types";

export const getTripDays = (
  pickUpDate: dayjs.Dayjs | undefined,
  pickUpTime: string,
  returnDate: dayjs.Dayjs | undefined,
  returnTime: string
) => {
  if (!pickUpDate || !returnDate) return 0;

  let days = dayjs(returnDate).diff(dayjs(pickUpDate), "days");
  if (pickUpTime < returnTime) {
    days += 1;
  }

  return days;
};

export const getInsuranceDays = (
  type: InsuranceType,
  pickUpDate: dayjs.Dayjs | undefined,
  pickUpTime: string,
  returnDate: dayjs.Dayjs | undefined,
  returnTime: string
) => {
  if (!pickUpDate || !returnDate) return 0;

  let DifferenceInDays;
  if (type === InsuranceType.QUALITAS) {
    DifferenceInDays = dayjs(returnDate).diff(dayjs(pickUpDate), "days");

    if (parseInt(pickUpTime.replace(":", "")) < 1200) DifferenceInDays += 1;
    if (parseInt(returnTime.replace(":", "")) > 1201) DifferenceInDays += 1;
  } else {
    DifferenceInDays = dayjs(returnDate).diff(dayjs(pickUpDate), "days");
    if (pickUpTime < returnTime) DifferenceInDays += 1;
  }

  return DifferenceInDays;
};

export const getDaysArray = (start: string, end: string) => {
  const dateList = [];

  const endDate = dayjs(end, "DD-MM-YYYY");
  const startDate = dayjs(start, "DD-MM-YYYY");

  if (startDate.isSame(endDate) || endDate.isBefore(startDate)) return [];

  for (
    let date = startDate;
    endDate.isAfter(date) || endDate.isSame(date);
    date = date.add(1, "day")
  ) {
    dateList.push(date);
  }

  return dateList;
};

// Get if a range of dates is available
export const isDateAvailable = (
  dates: DateRange<Dayjs>,
  storedDates: EditDateBlock[]
) => {
  return storedDates.every((date: EditDateBlock) => {
    const dateEnd = dayjs(dates[1]);
    const dateInitial = dayjs(dates[0]);
    const storedInitial = dayjs(date.startDate, "DD/MM/YYYY");
    const storedEnd = dayjs(date.endDate, "DD/MM/YYYY");

    return (
      (storedInitial > dateInitial && storedInitial > dateEnd) ||
      (storedEnd < dateEnd && storedEnd < dateInitial)
    );
  });
};

export const closeToStep = (time: string) => {
  const newHour = time.split(":");
  const meridiem = time.split(" ");
  if (newHour[1] > "30") {
    switch (newHour[0]) {
      case "11":
        return meridiem[1] === "AM" ? "12:00 PM" : "12:00 AM";
      case "12":
        return "1:00 " + meridiem[1];
      default:
        return parseInt(newHour[0]) + 1 + ":00 " + meridiem[1];
    }
  }

  return newHour[0] + ":30 " + meridiem[1];
};

export const getDaysFromTwoDates = (start: string, ends: string) => {
  return dayjs(ends).diff(dayjs(start), "days"); // "YYYY-MM-DD"
};
