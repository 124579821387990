import dayjs from "dayjs";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { closeToStep, getDaysArray } from "../../../lib/helpers/dateHandler";
import { DateStatus } from "../../../models/date/types";
import { EditDateBlock } from "../../../lib/components/carTools/editCarPrice/types";
import { FormikErrors } from "formik";
import { TripForm } from "../../../views/car/show/types";
import { homeFilter } from "../../../components/navHomeFilter/form";

interface BlockNextHalfHours {
  componentName: string;
  formatHours: string[];
  setBlockDates: Dispatch<SetStateAction<EditDateBlock[]>>;
  pickUpDate?: dayjs.Dayjs;
  returnDate?: dayjs.Dayjs;
  setFinalLeftHours: Dispatch<SetStateAction<string[]>>;
  setFinalRightHours: Dispatch<SetStateAction<string[]>>;
  pickUpTime: string;
  setFields: (
    field: string,
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<TripForm | homeFilter>>;
}

export const useBlockNextHalfHours = ({
  componentName,
  formatHours,
  setBlockDates,
  pickUpDate,
  returnDate,
  pickUpTime,
  setFinalLeftHours,
  setFinalRightHours,
  setFields,
}: BlockNextHalfHours) => {
  const [blockHours, setBlockHours] = useState<{
    date: string;
    untilHour: string;
  }>();

  useEffect(() => {
    blockNextTwelveHours();
  }, []);

  useEffect(() => {
    if (blockHours && pickUpDate && returnDate) {
      // Hour permited after 12 hours
      const blockNext = separateNextTwelveHours(
        dayjs(pickUpDate).format("MM/DD/YYYY") + " " + pickUpTime,
        dayjs(returnDate).format("MM/DD/YYYY")
      );

      const dataIndex = formatHours.indexOf(blockHours.untilHour);
      const pickUpIndex = formatHours.indexOf(blockNext.untilHour);

      // Reduce hours can be selected
      dayjs(pickUpDate).isSame(dayjs(blockHours.date, "DD/MM/YYYY"))
        ? setFinalLeftHours(formatHours.slice(dataIndex, formatHours.length))
        : setFinalLeftHours(formatHours);

      // Reduce hours can be selected
      dayjs(returnDate).isSame(dayjs(blockNext.date, "DD/MM/YYYY"))
        ? setFinalRightHours(formatHours.slice(pickUpIndex, formatHours.length))
        : dayjs(returnDate).isBefore(dayjs(blockNext.date, "DD/MM/YYYY"))
        ? (setFinalRightHours([]), resetTimes())
        : setFinalRightHours(formatHours);
    }
  }, [pickUpDate, returnDate, pickUpTime, blockHours]);

  const resetTimes = () => {
    if (componentName === "carForm") {
      setFields("returnTime", "");
    } else {
      setFields("hourEnd", "");
    }
  };

  const blockNextTwelveHours = () => {
    const result = dayjs().add(1, "hours");
    const today = dayjs();

    const list = getDaysArray(
      today.format("DD-MM-YYYY"),
      result.format("DD-MM-YYYY")
    );

    // Block next 12 hours
    setBlockHours({
      date: result.format("DD/MM/YYYY"),
      untilHour: closeToStep(result.format("h:mm A")),
    });

    if (list.length === 0) return;
    // Block today date
    setBlockDates((prev) => [
      ...prev,
      {
        id: prev[prev.length - 1] ? prev[prev.length - 1].id + 1 : 1,
        startDate: today.format("DD/MM/YYYY"),
        endDate: today.format("DD/MM/YYYY"),
        status: DateStatus.LOCKED_BY_RESERVATION,
        dates: list,
      },
    ]);
  };

  const separateNextTwelveHours = (firstDate: string, secondDate: string) => {
    const result = dayjs(firstDate).add(12, "hours");

    // Block next 12 hours

    if (
      closeToStep(result.format("h:mm A")) === "12:00 PM" &&
      dayjs(firstDate).format("MM/DD/YYYY") === secondDate
    ) {
      return {
        date: result.add(1, "day").format("DD/MM/YYYY"),
        untilHour: result.format("h:mm A"),
      };
    } else {
      return {
        date: result.format("DD/MM/YYYY"),
        untilHour: result.format("h:mm A"),
      };
    }
  };
};
