import { Trans, useTranslation } from "react-i18next";
import navbarImages from "../../../assets/images/navbar/index";
import "./show.scss";
import ClientMoreOptions from "../../../components/clientMoreOptions/ClientMoreOptions";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useParams } from "react-router-dom";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useContext, useEffect, useState } from "react";
import {
  getFileDetailsIfExist,
  uploadioEnv,
} from "../../../services/fileUploader";
import authenticationImages from "../../../assets/images/authentication";
import { useQuery } from "react-query";
import {
  editClientByHost,
  getClientByHost,
  getClientKYCByHost,
} from "../../../services/client";
import Loading from "../../../components/loading/component";
import dayjs from "dayjs";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import WarningOutlineIcon from "@mui/icons-material/ErrorOutline";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import CheckIcon from "@mui/icons-material/Check";
import {
  TrustedUser,
  UserAccountStatus,
  UserStatus,
} from "../../../models/user/types";
import { Link } from "react-router-dom";
import { ClientTrips } from "../../../lib/components/clientTools/clientTrips/ClientTrips";
import {
  ClientKYC,
  ClientKYCNotPaid,
} from "../../../lib/components/clientTools/clientKyc/ClientKyc";
import { DomainContext } from "../../../providers/domainProvider";
import { MenuItem, Select } from "@mui/material";

export const ShowClient = () => {
  const { settings } = useContext(DomainContext);
  const [trustLevel, setTrustLevel] = useState<TrustedUser | undefined>();
  const { t } = useTranslation(["client", "routes"]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { id } = useParams();

  const { data: client, isLoading } = useQuery(
    ["client", id],
    () => getClientByHost(id || "0"),
    { enabled: !!id }
  );

  const { data: clientKYCReport } = useQuery(
    ["clientKYCReport", client?.id],
    () => getClientKYCByHost(client?.id.toString() || "0"),
    { enabled: !!client }
  );

  const { data: clientImage } = useQuery(
    ["hostImage", id],
    () =>
      getFileDetailsIfExist(
        `/${uploadioEnv}/user-${id}/foto_perfil`,
        `/${uploadioEnv}/user-${id}`
      ),
    {
      enabled: !!id,
    }
  );

  useEffect(() => {
    if (client) {
      setTrustLevel(client.trustedUser);
    }
  }, [client]);

  useEffect(() => {
    const updateTrustLevel = async (id: number, trustedUser: TrustedUser) => {
      await editClientByHost({ id, trustedUser });
    };
    if (trustLevel && client && trustLevel !== client.trustedUser) {
      updateTrustLevel(client.id, trustLevel);
    }
  }, [trustLevel]);

  if (!client || isLoading) {
    return <Loading height="1vh" color="#fff" />;
  }

  const emailStatus =
    client.status === UserAccountStatus.REGISTER_NO_ACTIVE_PHONE ||
    client.status === UserAccountStatus.ACTIVE ? (
      <div className="status">
        <CheckCircleOutlineIcon color="success" />
        <p style={{ color: "#2E7D32" }}>Verificado</p>
      </div>
    ) : (
      <div className="status">
        <CancelOutlinedIcon color="error" />
        <p style={{ color: "#C62828" }}>No verificado</p>
      </div>
    );

  const phoneStatus =
    client.status === UserAccountStatus.REGISTER_NO_ACTIVE_EMAIL ||
    client.status === UserAccountStatus.ACTIVE ? (
      <div className="status">
        <CheckCircleOutlineIcon color="success" />
        <p style={{ color: "#2E7D32" }}>Verificado</p>
      </div>
    ) : (
      <div className="status">
        <CancelOutlinedIcon color="error" />
        <p style={{ color: "#C62828" }}>No verificado</p>
      </div>
    );

  const clientStatus =
    client.isBlocked === UserStatus.VALID ? (
      <div className="status">
        <CheckCircleOutlineIcon color="success" />
        <p style={{ color: "#2E7D32" }}>Autorizado</p>
      </div>
    ) : (
      <div className="status">
        <CancelOutlinedIcon color="error" />
        <p style={{ color: "#C62828" }}>Bloqueado</p>
      </div>
    );

  const clientRegister =
    client.status === UserAccountStatus.INVITED ? (
      <div className="status">
        <MarkEmailReadIcon color="primary" />
        <p style={{ color: "#4885CB" }}>Invitado</p>
      </div>
    ) : (
      <div className="status">
        <CheckCircleOutlineIcon color="success" />
        <p style={{ color: "#2E7D32" }}>Completado</p>
      </div>
    );

  return (
    <article className="client-details-container">
      <div className="client-details__join-container">
        <div className="client-details__title-container">
          <div className="client-details__route">
            <p className="client-details__route__first">{t("index.manages")}</p>
            <img
              src={navbarImages.arrowRight}
              className="client-details__route__arrow"
            />
            <Link
              to={`/${t("manage", { ns: "routes" })}/${t("clients", {
                ns: "routes",
              })}`}
              className="client-details__route__second"
            >
              {t("index.clients")}
            </Link>
            <img
              src={navbarImages.arrowRight}
              className="client-details__route__arrow"
            />
            <p className="client-details__route__second">{t("show.title")}</p>
          </div>
          <h1 data-testid="my-cars-title" className="client-details__title">
            {t("show.title")}
          </h1>
        </div>
      </div>
      <div className="client-details__user__information">
        <div className="client-details__user__information__title">
          <h2>{t("show.client_info.general_info")}</h2>
          <div className="client-details__user__information__title__button">
            <button
              className="btn-options"
              data-testid="show-options-button"
              onClick={(e) => setAnchorEl(e.currentTarget)}
            >
              <MoreVertIcon />
            </button>
            <ClientMoreOptions
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              client={client}
            />
          </div>
        </div>
        <div className="client-details__user__information__container">
          <div className="client-details__user__profile">
            <img
              src={
                clientImage
                  ? clientImage.fileUrl.replace("raw", "profile-picture") +
                    `?v=${clientImage.etag}`
                  : authenticationImages.profileDefault
              }
              alt="client-image"
            />
            <div className="client-details__user__profile__info">
              <h3>{`${client.name} ${client.surName}`}</h3>
              <Trans>
                <p>
                  {t("show.client_info.create_date", {
                    createDate: dayjs(
                      client.createDate,
                      "DD/MM/YYYY HH:mm:ss"
                    ).format("DD-MMMM-YYYY"),
                  })}
                </p>
                {clientKYCReport && (
                  <p>
                    {t("show.client_info.age", {
                      age: clientKYCReport.age,
                    })}
                  </p>
                )}
              </Trans>
            </div>
          </div>
          <div className="client-details__user__trusted">
            <h4>{t("show.client_info.trust_level")}</h4>
            <div className="client-details__user__trusted__info">
              {trustLevel !== undefined && (
                <Select
                  value={trustLevel}
                  className="client-details__user__trusted__select"
                  onChange={(e) => {
                    setTrustLevel(e.target.value as TrustedUser);
                  }}
                >
                  <MenuItem
                    sx={{ display: "flex", gap: "6px" }}
                    value={TrustedUser.REGULAR}
                  >
                    <CheckIcon color="action" />
                    <p style={{ marginBottom: 0, color: "#4A4A57" }}>
                      {t("show.client_info.regular")}
                    </p>
                  </MenuItem>
                  <MenuItem
                    sx={{ display: "flex", gap: "6px" }}
                    value={TrustedUser.TRUSTED}
                  >
                    <CheckCircleOutlineIcon color="success" />
                    <p style={{ marginBottom: 0, color: "#2E7D32" }}>
                      {t("show.client_info.trusted")}
                    </p>
                  </MenuItem>
                  <MenuItem
                    sx={{ display: "flex", gap: "6px" }}
                    value={TrustedUser.DOUBTFUL}
                  >
                    <WarningOutlineIcon color="warning" />
                    <p style={{ marginBottom: 0, color: "#F9A825" }}>
                      {t("show.client_info.doubtful")}
                    </p>
                  </MenuItem>
                  <MenuItem
                    sx={{ display: "flex", gap: "6px" }}
                    value={TrustedUser.NOT_TRUSTED}
                  >
                    <CancelOutlinedIcon color="error" />
                    <p style={{ marginBottom: 0, color: "#C62828" }}>
                      {t("show.client_info.not_trusted")}
                    </p>
                  </MenuItem>
                </Select>
              )}
            </div>
          </div>
          <div className="client-details__user__contact">
            <h4>{t("show.client_info.contact_info")}</h4>
            <div className="client-details__user__contact__info">
              <EmailOutlinedIcon className="icon" />
              <p>{client.email}</p> {emailStatus}
            </div>
            <div className="client-details__user__contact__info">
              <LocalPhoneIcon className="icon" />
              <p>{client.phone}</p> {phoneStatus}
            </div>
          </div>
          <div className="client-details__user__additional">
            <h4>{t("show.client_info.additional_info")}</h4>
            <div className="client-details__user__additional__info">
              <p>{t("show.client_info.status")}</p> {clientStatus}
            </div>
            <div className="client-details__user__additional__info">
              <p>{t("show.client_info.user_type")}</p> {clientRegister}
            </div>
          </div>
        </div>
      </div>
      {settings.advancedFeatures.kyc ? (
        <>
          {clientKYCReport && (
            <ClientKYC client={client} clientKYCReport={clientKYCReport} />
          )}
        </>
      ) : (
        <ClientKYCNotPaid />
      )}
      <ClientTrips client={client} />
    </article>
  );
};
