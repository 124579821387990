import { Link } from "react-router-dom";
import h_homepageImages from "../../../assets/images/home/h_homepage";
import navbarImages from "../../../assets/images/navbar";
import { CarCarousel } from "../../../lib/components/carTools/carCarousel/CarCarousel";
import { CarForm } from "../../../lib/components/carTools/carForm/CarForm";
import { TranslationContext } from "../../driverReservation/show/component";
import "./ShowCar.scss";
import { ShowContext } from "./types";
import Toast from "../../../components/toast/component";
import a_carsImages from "../../../assets/images/a_cars";
import { CarSuggestions } from "../../../lib/components/carTools/carForm/carSuggestions/CarSuggestions";
import { Helmet } from "react-helmet";
import { carShortInformation } from "../../../lib/helpers/textHandler";
import { useShowCar } from "../../../hooks/car/show/useShowCar";
import { FirstMessageModal } from "../../../components/modals/firstMessage/FirstMessage";
import { useContext, useState } from "react";
import { LoginContinueModal } from "../../../components/modals/loginContinue/component";
import { getToken } from "../../../services/localstorage";
import { DomainContext } from "../../../providers/domainProvider";

export const Car = () => {
  const {
    t,
    carData,
    userData,
    addressesData,
    search,
    openAlert,
    setOpenAlert,
    errorMsg,
    setErrorMsg,
    preview,
    id,
    carId,
  } = useShowCar();
  const { settings } = useContext(DomainContext);
  const [isFirstMessageOpen, setIsFirstMessageOpen] = useState<boolean>(false);
  const [isLoginContinueModalOpen, setIsLoginContinueModalOpen] =
    useState<boolean>(false);

  return (
    <ShowContext.Provider
      value={{
        car: carData,
        host: userData,
        addresses: addressesData,
      }}
    >
      <Helmet>
        <title>
          {carData &&
            userData &&
            t("seo.show.title", {
              car: carShortInformation(carData),
              user: userData.name,
              city: search.get("state"),
            })}
        </title>
        <meta
          name="description"
          content={
            carData &&
            userData &&
            t("seo.show.description", {
              car: carShortInformation(carData),
              user: userData.name,
              city: search.get("state"),
            })
          }
        />
        <meta
          property="og:title"
          content={
            carData &&
            userData &&
            t("seo.show.og_title", {
              car: carShortInformation(carData),
              user: userData.name,
              city: search.get("state"),
            })
          }
        />
        <meta
          property="og:description"
          content={
            carData &&
            userData &&
            t("seo.show.og_description", {
              car: carShortInformation(carData),
              user: userData.name,
              city: search.get("state"),
            })
          }
        />
        <meta name="robots" content="index,follow" />

        <link
          rel="canonical"
          href={`https://romio.mx/detalles-de-auto/${id}`}
        />
        <meta
          property="og:url"
          content={`https://romio.mx/detalles-de-auto/${id}`}
        />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content={t("seo.show.og_locale")} />
        <link
          rel="alternate"
          hrefLang="es"
          href={`https://romio.mx/detalles-de-auto/${id}`}
        />
        <link
          rel="alternate"
          hrefLang="en"
          href={`https://romio.mx/en/detalles-de-auto/${id}`}
        />
        <link
          rel="alternate"
          hrefLang="x-default"
          href={`https://romio.mx/detalles-de-auto/${id}`}
        />
      </Helmet>
      <Toast open={openAlert} setOpen={setOpenAlert} type="error">
        {errorMsg}
      </Toast>
      <TranslationContext.Provider value={t}>
        <div className="car-container-show-car">
          <CarCarousel />

          <div className="btn-container">
            <div className="btn-container-icon">
              <p className="share">{t("show.contact_bar.share")}</p>
              <a
                id="compartir"
                target="_blank"
                className="btn btn-orange-icon"
                href={`https://api.whatsapp.com/send?text=*${carData?.brand} ${carData?.model} ${carData?.year}*%0a%0a¡Encontre este auto increible en *Romio*!%0a%0a${process.env.REACT_APP_FRONTEND_URL}/detalles-de-auto/${id}`}
              >
                <img src={h_homepageImages.whatsapp} alt="" />
              </a>
              <a
                id="compartir"
                target="_blank"
                className="btn btn-orange-icon"
                href={`https://www.facebook.com/sharer/sharer.php?u=${process.env.REACT_APP_FRONTEND_URL}/detalles-de-auto/${id}`}
              >
                <img src={h_homepageImages.facebook} alt="" />
              </a>
              <a
                id="compartir1"
                target="_blank"
                className="btn btn-orange-icon"
                href={`https://twitter.com/intent/tweet?text=${carData?.brand}%20${carData?.model}%20${carData?.year}%0a%0a%C2%A1Encontre%20un%20auto%20increible%20en%20romio!%0a%0a&url=${process.env.REACT_APP_FRONTEND_URL}/detalles-de-auto/${id}%0a%0a&hashtags=carsharing,rentadeautos,masqueunarenta`}
              >
                <img src={h_homepageImages.twitter} alt="" />
              </a>
              <a
                id="compartir2"
                target="_blank"
                className="btn btn-orange-icon"
                onClick={() => {
                  navigator.clipboard.writeText(
                    process.env.REACT_APP_FRONTEND_URL +
                      "/detalles-de-auto/" +
                      id
                  );
                }}
              >
                <img src={h_homepageImages.share} alt="" />
              </a>
            </div>
            <button
              id="contact"
              data-testid="contact-button"
              onClick={() => {
                if (settings.advancedFeatures.chat) {
                  const isLoggedIn = !!getToken() && !!userData;
                  setIsFirstMessageOpen(isLoggedIn);
                  setIsLoginContinueModalOpen(!isLoggedIn);
                } else {
                  const userName = userData?.name || "Anónimo";
                  const carDetails = `${carData?.brand} ${carData?.model} ${carData?.year}`;
                  const url = `${process.env.REACT_APP_FRONTEND_URL}/detalles-de-auto/${id}`;
                  const waMessage = `https://wa.me/523321688080?text=Quiero%20mas%20informaci%C3%B3n%20sobre:%20${carDetails}%20de%20${userName}%0A${url}`;

                  window.open(waMessage, "_blank");
                }
              }}
              className="btn btn-orange"
            >
              <p>{t("show.contact_bar.contact")}</p>
              <img src={navbarImages.messages} alt="" />
            </button>
          </div>

          <CarForm
            setErrorMsg={setErrorMsg}
            setOpenAlert={setOpenAlert}
            preview={preview}
          />

          {preview ? (
            <div className="preview-fixed">
              <div className="preview-text">
                <img src={a_carsImages.preview} alt="" />
                <h1>{t("show.preview_fixed.preview")}</h1>
              </div>
              <Link
                to={
                  `/${t("manage", { ns: "routes" })}/${t("my_cars", {
                    ns: "routes",
                  })}/${t("edit", { ns: "routes" })}/` + carId
                }
                className="btn btn-close-orange"
              >
                {t("show.preview_fixed.close")}
              </Link>
            </div>
          ) : null}
          <CarSuggestions
            componentColor={""}
            title={t("show.car_form.suggestions")}
            titlePosition="left"
          />
        </div>
        <FirstMessageModal
          isOpen={isFirstMessageOpen}
          setOpen={() => setIsFirstMessageOpen(false)}
          setErrorMsg={setErrorMsg}
          setOpenAlert={setOpenAlert}
        />
        <LoginContinueModal
          isOpen={isLoginContinueModalOpen}
          setOpen={() => setIsLoginContinueModalOpen(false)}
          setErrorMsg={setErrorMsg}
          setOpenAlert={setOpenAlert}
        />
      </TranslationContext.Provider>
    </ShowContext.Provider>
  );
};
