import { useContext, useEffect } from "react";
import { DomainContext } from "./domainProvider";

export const PaletteProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const { settings } = useContext(DomainContext);

  useEffect(() => {
    if (settings) {
      document.body.style.setProperty(
        "--Brand-Colors-Primary-Color",
        settings.palette.primary
      );
      document.body.style.setProperty(
        "--Brand-Colors-Secondary-Color",
        settings.palette.secondary
      );
    }
  }, [settings]);

  return <>{children}</>;
};
