import React, { useMemo, useState } from "react";
import Loading from "../../../../components/loading/component";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { getTripsByClient } from "../../../../services/client";
import "./ClientTrips.scss";
import { MenuItem, Select } from "@mui/material";
import { useWindowSize } from "../../../../hooks/windowSize";
import images from "../../../../assets/images/a_cars/index";
import AddIcon from "@mui/icons-material/Add";
import { ClientTripCard } from "../clientTripCard/ClientTripCard";
import { Client } from "../../../../models/client/types";
import { useFormik } from "formik";
import { ClientTripForm, ClientTripSchema } from "./form";
import { Link } from "react-router-dom";

export const ClientTrips = ({ client }: { client: Client }) => {
  const { t } = useTranslation(["client", "routes"]);
  const { data: tripList, isLoading } = useQuery(
    ["trips"],
    () => getTripsByClient(client.id.toString()),
    { enabled: !!client }
  );
  const windowSize = useWindowSize();

  const fetchEditClientTrip = () => {
    //order
  };

  const formikClientTrips = useFormik<ClientTripForm>({
    initialValues: {
      order: "",
    },
    validationSchema: ClientTripSchema,
    onSubmit: fetchEditClientTrip,
  });

  const trips = useMemo(() => {
    if (tripList) {
      return tripList.sort(
        (firstTrip, secondTrip) => secondTrip.id - firstTrip.id
      );
    }
  }, [tripList]);

  if (isLoading) {
    return <Loading height="25vh" />;
  }

  if (!trips) {
    return <></>;
  }

  return (
    <>
      <article className="client-reservation-container">
        <div className="client-reservation-card">
          <div className="client-reservation-card__title-container">
            <div className="client-reservation-card__title">
              <h3>{t("show.rental_history")}</h3>
              <p>Numero de reservaciones: {client.totalTrips}</p>
            </div>
            <div className="client-reservation-card__filters">
              <Select
                size="small"
                displayEmpty
                value={formikClientTrips.values.order}
                onChange={() => formikClientTrips.handleChange("order")}
                className="client-reservation-card__filters__select"
              >
                <MenuItem value={""} disabled>
                  {t("show.sort_by")}
                </MenuItem>
              </Select>
              {windowSize < 1170 ? (
                <>
                  <Link
                    to={`/${t("routes:manage")}/${t("routes:trips")}/${t(
                      "routes:create_trip"
                    )}`}
                    className="client-reservation__search__add"
                  >
                    <AddIcon />
                  </Link>
                </>
              ) : (
                <Link
                  to={`/${t("routes:manage")}/${t("routes:trips")}/${t(
                    "routes:create_trip"
                  )}`}
                  data-testid="add-button"
                  className="btn btn-add"
                >
                  <img src={images.plus} alt="plus" aria-hidden="true" />
                  <p>{t("show.create_reservation")}</p>
                </Link>
              )}
            </div>
          </div>
          <div className="card-trip">
            <ul className="list-group list-group-flush" data-testid="car-list">
              {isLoading ? (
                <Loading height="60vh" />
              ) : (
                trips.map((trip, index) => (
                  <React.Fragment key={trip.id + "-" + index + trip.tripCode}>
                    <ClientTripCard trip={trip} client={client} />
                  </React.Fragment>
                ))
              )}
            </ul>
          </div>
        </div>
      </article>
    </>
  );
};
