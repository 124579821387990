import * as Yup from "yup";
import { Dispatch, SetStateAction } from "react";
import { Client } from "../../../models/client/types";
import { TripStatus, TripType } from "../../../models/trip/types";
import dayjs from "dayjs";

export type AddReservationProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  client?: Client;
};

export interface AddReservationForm {
  carId: number;
  hostId: number;
  driverUserId: number;
  status: TripStatus;
  pickUpAddressId: number;
  pickUpPrice: number;
  pickUpType: number;
  pickUpDate: dayjs.Dayjs;
  pickUpTime: string;
  returnAddressId: number;
  returnPrice: number;
  returnType: number;
  returnDate: dayjs.Dayjs;
  returnTime: string;
}

export interface AddReservationFormDTO
  extends Omit<AddReservationForm, "pickUpDate" | "returnDate"> {
  pickUpDate: string;
  returnDate: string;
  type: TripType;
}

export const addReservationSchema: Yup.SchemaOf<AddReservationForm> =
  Yup.object().shape({
    carId: Yup.number().required().moreThan(0, "Necesitas elegir el auto"),
    hostId: Yup.number().required().moreThan(0),
    driverUserId: Yup.number()
      .required()
      .moreThan(0, "Necesitas elegir el cliente"),
    status: Yup.mixed().oneOf(Object.values(TripStatus)),
    pickUpAddressId: Yup.number()
      .required()
      .moreThan(0, "Necesitas elegir la direccion de entrega"),
    pickUpPrice: Yup.number().required(),
    pickUpType: Yup.number().required(),
    pickUpDate: Yup.object()
      .shape({})
      .required("La fecha de inicio es requerida"),
    pickUpTime: Yup.string().required(),
    returnAddressId: Yup.number()
      .required()
      .moreThan(0, "Necesitas elegir la direccion de devolución"),
    returnPrice: Yup.number().required(),
    returnType: Yup.number().required(),
    returnDate: Yup.object()
      .shape({})
      .required("La fecha de termino es requerida"),
    returnTime: Yup.string().required(),
  });
