import { Trans, useTranslation } from "react-i18next";
import "./ClientKyc.scss";
import {
  Client,
  ClientKYCReport,
  KycMoffinStatus,
  RiskScores,
} from "../../../../models/client/types";
import { Chip, Step, StepContent, StepLabel, Stepper } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import WarningOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckIcon from "@mui/icons-material/Check";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { DocStatus } from "../../../../models/user/types";
import { KycStatus } from "../../../../views/profile/types";
import CloseIcon from "@mui/icons-material/Close";
import {
  getFileDetailsFromPath,
  uploadioEnv,
} from "../../../../services/fileUploader";
import { useEffect, useState } from "react";
import UploadDropZone from "../../../../components/uploadDropZone/component";
import clientImages from "../../../../assets/images/client";
import dayjs from "dayjs";
import { getUserId } from "../../../../services/localstorage";
import { DocumentIconStatus } from "../../../../components/profileDocuments/component";
import { useQueryClient } from "react-query";
import { editClientByHost } from "../../../../services/client";
import Loading from "../../../../components/loading/component";

export const ClientKYC = ({
  client,
  clientKYCReport,
}: {
  client: Client;
  clientKYCReport: ClientKYCReport;
}) => {
  const { t } = useTranslation(["client", "routes"]);
  const [proofOfAddress, setProofOfAddress] = useState<
    { url: string; mime: string } | undefined
  >();
  const [decline, setDecline] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const [bankStatement, setBankStatement] = useState<
    { url: string; mime: string } | undefined
  >();

  const declineDoc = async (
    doc: "docOfficialDocuments" | "docBankStatement" | "docProofOfAddress"
  ) => {
    const result = await editClientByHost({
      id: client.id,
      [doc]:
        doc === "docOfficialDocuments" ? KycStatus.FAIL : DocStatus.REFUSED,
    });

    if (result?.status === 200) {
      await queryClient.invalidateQueries(["client"]);
    }
    setDecline(false);
  };

  const getFileDetails = async (id: number) => {
    await getFileDetailsFromPath(
      `/${process.env.REACT_APP_UPLOAD_IO_ENV}/user-${id}/comprobante_domicilio`,
      setProofOfAddress
    );
    await getFileDetailsFromPath(
      `/${process.env.REACT_APP_UPLOAD_IO_ENV}/user-${id}/estado_cuenta`,
      setBankStatement
    );
  };

  useEffect(() => {
    if (client) {
      getFileDetails(client.id);
    }
  }, [client]);

  const clientId =
    client.docOfficialDocuments === KycStatus.PENDING ||
    client.docOfficialDocuments === KycStatus.SUCCESS ? (
      <div className="status">
        <div className="status-text">
          <CheckCircleOutlineIcon color="success" />
          <p style={{ color: "#2E7D32" }}>
            {t("show.client_information.completed")}
          </p>
        </div>
        <a
          onClick={() => {
            setDecline(true);
            declineDoc("docOfficialDocuments");
          }}
        >
          {t("show.client_security.decline_sent")}
          <AutorenewIcon color="primary" />
        </a>
      </div>
    ) : client.docOfficialDocuments === KycStatus.NOT_STARTED ? (
      <div className="status">
        <div className="status-text">
          <WarningOutlineIcon color="error" />
          <p style={{ color: "#F9A825" }}>
            {t("show.client_information.incomplete")}
          </p>
        </div>
      </div>
    ) : (
      <div className="status">
        <div className="status-text">
          <CloseIcon color="error" />
          <p style={{ color: "#C62828" }}>
            {t("show.client_information.refused")}
          </p>
        </div>
        <p>
          {t("show.client_security.sent")} <CheckIcon color="action" />
        </p>
      </div>
    );

  const clientLicense =
    client.docBankStatement === DocStatus.PENDING ||
    client.docBankStatement === DocStatus.VALIDATED ? (
      <div className="status">
        <div className="status-text">
          <CheckCircleOutlineIcon color="success" />
          <p style={{ color: "#2E7D32" }}>
            {t("show.client_information.completed")}
          </p>
        </div>
        <a
          onClick={() => {
            setDecline(true);
            declineDoc("docBankStatement");
          }}
        >
          {t("show.client_security.decline_sent")}
          <AutorenewIcon color="primary" />
        </a>
      </div>
    ) : DocStatus.NOT_UPLOADED ? (
      <div className="status">
        <div className="status-text">
          <WarningOutlineIcon color="error" />
          <p style={{ color: "#F9A825" }}>
            {t("show.client_information.incomplete")}
          </p>
        </div>
      </div>
    ) : (
      <div className="status">
        <div className="status-text">
          <CloseIcon color="error" />
          <p style={{ color: "#C62828" }}>
            {t("show.client_information.refused")}
          </p>
        </div>
        <p>
          {t("show.client_security.sent")} <CheckIcon color="action" />
        </p>
      </div>
    );

  const clientProofAddress =
    client.docProofOfAddress === DocStatus.PENDING ||
    client.docProofOfAddress === DocStatus.VALIDATED ? (
      <div className="status">
        <div className="status-text">
          <CheckCircleOutlineIcon color="success" />
          <p style={{ color: "#2E7D32" }}>
            {t("show.client_information.completed")}
          </p>
        </div>
        <a
          onClick={() => {
            setDecline(true);
            declineDoc("docProofOfAddress");
          }}
        >
          {t("show.client_security.decline_sent")}
          <AutorenewIcon color="primary" />
        </a>
      </div>
    ) : client.docProofOfAddress === DocStatus.NOT_UPLOADED ? (
      <div className="status">
        <div className="status-text">
          <WarningOutlineIcon color="error" />
          <p style={{ color: "#F9A825" }}>
            {t("show.client_information.incomplete")}
          </p>
        </div>
      </div>
    ) : (
      <div className="status">
        <div className="status-text">
          <CloseIcon color="error" />
          <p style={{ color: "#C62828" }}>
            {t("show.client_information.refused")}
          </p>
        </div>
        <p>
          {t("show.client_security.sent")} <CheckIcon color="action" />
        </p>
      </div>
    );

  const handleMoffinGrade = (value: RiskScores) => {
    switch (value) {
      case 100:
        return (
          <Chip
            icon={<CancelOutlinedIcon color="error" />}
            label={value}
            className="status-item__chip"
            sx={{
              backgroundColor: "#FFEBEE",
              color: "#C62828",
              padding: "4px 16px",
            }}
          />
        );
      case 25:
      case 50:
        return (
          <Chip
            icon={<WarningOutlineIcon color="warning" />}
            label={value}
            className="status-item__chip"
            sx={{
              backgroundColor: "#FFF8E1",
              color: "#F9A825",
              padding: "4px 16px",
            }}
          />
        );
      case 0:
        return (
          <Chip
            icon={<CheckCircleOutlineIcon color="success" />}
            label={value}
            className="status-item__chip"
            sx={{
              backgroundColor: "#E8F5E9",
              color: "#2E7D32",
              padding: "4px 16px",
            }}
          />
        );
    }
  };

  const handleMoffinStatus = (value: KycMoffinStatus) => {
    switch (value) {
      case KycMoffinStatus.NOT_EXECUTED:
        return (
          <Chip
            icon={<CancelOutlinedIcon color="error" />}
            label={"NOT_EXECUTED"}
            className="status-item__chip"
            sx={{
              backgroundColor: "#FFEBEE",
              color: "#C62828",
              padding: "4px 16px",
            }}
          />
        );
      case KycMoffinStatus.REJECTED:
        return (
          <Chip
            icon={<CancelOutlinedIcon color="error" />}
            label={"REJECTED"}
            className="status-item__chip"
            sx={{
              backgroundColor: "#FFEBEE",
              color: "#C62828",
              padding: "4px 16px",
            }}
          />
        );
      case KycMoffinStatus.WARNING:
        return (
          <Chip
            icon={<WarningOutlineIcon color="warning" />}
            label={"WARNING"}
            className="status-item__chip"
            sx={{
              backgroundColor: "#FFF8E1",
              color: "#F9A825",
              padding: "4px 16px",
            }}
          />
        );
      case KycMoffinStatus.PASSED:
        return (
          <Chip
            icon={<CheckCircleOutlineIcon color="success" />}
            label={"PASSED"}
            className="status-item__chip"
            sx={{
              backgroundColor: "#E8F5E9",
              color: "#2E7D32",
              padding: "4px 16px",
            }}
          />
        );
    }
  };

  return (
    <article className="client-kyc-container">
      <div className="client-kyc__information__title">
        <h2>{t("show.client_kyc.security_info")}</h2>
        <p>{t("show.client_kyc.info")}</p>
      </div>

      <div className="client-kyc__main-status">
        <h3>{t("show.client_kyc.results")}</h3>
        <div className="client-kyc__status-item-container-decision">
          <h4>{t("show.client_kyc.decision")}</h4>
          <div className="client-kyc__status-item-trust">
            <div className="client-kyc__status-item">
              <p className="status-item__title">
                {t("show.client_kyc.result")}
              </p>
              {handleMoffinStatus(clientKYCReport.general.result)}
            </div>
            <div className="client-kyc__status-item">
              <p className="status-item__title">{t("show.client_kyc.grade")}</p>
              {handleMoffinGrade(clientKYCReport.general.score)}
            </div>
          </div>
        </div>

        <div className="client-kyc__status-item-container-criteria">
          <h4>{t("show.client_kyc.criteria")}</h4>
          <div className="client-kyc__status-item-container">
            <div className="client-kyc__status-item">
              <p className="status-item__title">{t("show.client_kyc.id")}</p>
              {handleMoffinStatus(clientKYCReport.usabilityId)}
            </div>
            <div className="client-kyc__status-item">
              <p className="status-item__title">
                {t("show.client_kyc.selfie")}
              </p>
              {handleMoffinStatus(clientKYCReport.usabilitySelfie)}
            </div>
            <div className="client-kyc__status-item">
              <p className="status-item__title">
                {t("show.client_kyc.facemap")}
              </p>
              {handleMoffinStatus(clientKYCReport.usabilityFaceMap)}
            </div>
            <div className="client-kyc__status-item">
              <p className="status-item__title">
                {t("show.client_kyc.extraction")}
              </p>
              {handleMoffinStatus(clientKYCReport.extraction.type)}
              <span className="label-text">
                {clientKYCReport.extraction.label}
              </span>
            </div>
            <div className="client-kyc__status-item">
              <p className="status-item__title">
                {t("show.client_kyc.images")}
              </p>
              {handleMoffinStatus(clientKYCReport.imageReview)}
            </div>
            <div className="client-kyc__status-item">
              <p className="status-item__title">{t("show.client_kyc.data")}</p>
              {handleMoffinStatus(clientKYCReport.dataReview.type)}
              <span className="label-text">
                {clientKYCReport.dataReview.label}
              </span>
            </div>
            <div className="client-kyc__status-item">
              <p className="status-item__title">{t("show.client_kyc.life")}</p>
              {handleMoffinStatus(clientKYCReport.liveness.type)}
              <span className="label-text">
                {clientKYCReport.liveness.label}
              </span>
            </div>
            <div className="client-kyc__status-item">
              <p className="status-item__title">
                {t("show.client_kyc.similarity")}
              </p>
              {handleMoffinStatus(clientKYCReport.similarityMatch.type)}
              <span className="label-text">
                {clientKYCReport.similarityMatch.label}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="dropdown-divider"></div>

      <div className="client-security__flows-docs">
        <h3>{t("show.client_security.title")}</h3>

        {!decline ? (
          <Stepper
            orientation="vertical"
            sx={{ width: "100%" }}
            className="client-security__flows-docs-stepper"
          >
            <Step active>
              <StepLabel
                StepIconComponent={DocumentIconStatus}
                icon={
                  client.docOfficialDocuments === KycStatus.NOT_STARTED
                    ? "NOT_UPLOADED"
                    : client.docOfficialDocuments === KycStatus.PENDING ||
                      client.docOfficialDocuments === KycStatus.SUCCESS
                    ? "SUCCESS"
                    : "FAIL"
                }
                StepIconProps={{
                  sx: {
                    color:
                      client.docOfficialDocuments === KycStatus.NOT_STARTED
                        ? "#3cb2b9"
                        : client.docOfficialDocuments === KycStatus.PENDING ||
                          client.docOfficialDocuments === KycStatus.SUCCESS
                        ? "#98d075"
                        : "#eb5757",
                  },
                }}
              >
                <h3>{t("show.client_security.id")}</h3>
                {clientId}
              </StepLabel>
              <StepContent sx={{ paddingLeft: "32px" }}>
                <p>{t("show.client_security.needed_documents.title")}</p>
                <ul>
                  <li>{t("show.client_security.needed_documents.first")}</li>
                  <li>{t("show.client_security.needed_documents.second")}</li>
                </ul>
                <a href="">
                  {t("show.client_security.needed_documents.more_info")}
                </a>
              </StepContent>
            </Step>
            <Step active>
              <StepLabel
                StepIconComponent={DocumentIconStatus}
                icon={
                  client.docBankStatement === DocStatus.NOT_UPLOADED
                    ? "NOT_UPLOADED"
                    : client.docBankStatement === DocStatus.PENDING ||
                      client.docBankStatement === DocStatus.VALIDATED
                    ? "SUCCESS"
                    : "FAIL"
                }
                StepIconProps={{
                  sx: {
                    color:
                      client.docBankStatement === DocStatus.NOT_UPLOADED
                        ? "#3cb2b9"
                        : client.docBankStatement === DocStatus.PENDING ||
                          client.docBankStatement === DocStatus.VALIDATED
                        ? "#98d075"
                        : "#eb5757",
                  },
                }}
              >
                <h3>{t("show.client_security.driving_license.title")}</h3>
                {clientLicense}
              </StepLabel>
              <StepContent sx={{ paddingLeft: "32px" }}>
                <p>{t("show.client_security.driving_license.license_proof")}</p>
                <p>{t("show.client_security.driving_license.warning")}</p>
              </StepContent>
            </Step>
            <Step active>
              <StepLabel
                StepIconComponent={DocumentIconStatus}
                icon={
                  client.docProofOfAddress === DocStatus.NOT_UPLOADED
                    ? "NOT_UPLOADED"
                    : client.docProofOfAddress === DocStatus.PENDING
                    ? "PENDING"
                    : client.docProofOfAddress === DocStatus.VALIDATED
                    ? "SUCCESS"
                    : "FAIL"
                }
                StepIconProps={{
                  sx: {
                    color:
                      client.docProofOfAddress === DocStatus.NOT_UPLOADED
                        ? "#3cb2b9"
                        : client.docProofOfAddress === DocStatus.PENDING
                        ? "#ffab03"
                        : client.docProofOfAddress === DocStatus.VALIDATED
                        ? "#98d075"
                        : "#eb5757",
                  },
                }}
              >
                <h3>{t("show.client_security.residence_proof.title")}</h3>
                {clientProofAddress}
              </StepLabel>
              <StepContent sx={{ paddingLeft: "32px" }}>
                <p>{t("show.client_security.residence_proof.proof")}</p>
                <p>{t("show.client_security.residence_proof.warning")}</p>
              </StepContent>
            </Step>
          </Stepper>
        ) : (
          <Loading />
        )}
      </div>

      <div className="dropdown-divider"></div>

      <div className="client-kyc__information">
        <h3>{t("show.client_information.title")}</h3>
        <div className="client-kyc__information-container">
          <div className="client-kyc__information-text">
            <div className="information-text-item">
              <p>{clientKYCReport.name}</p>
              <span>{t("show.client_information.full_name")}</span>
            </div>
            <div className="information-text-item">
              <p>{clientKYCReport.lastName}</p>
              <span>{t("show.client_information.surname")}</span>
            </div>
            <div className="information-text-item">
              <p>
                {`${dayjs(clientKYCReport.birthDay, "DD/MM/YYYY").format(
                  "DD-MMMM-YYYY"
                )} • ${clientKYCReport.age} años`}
              </p>
              <span>{t("show.client_information.birth_date")}</span>
            </div>
            <div className="information-text-item">
              <p>
                {clientKYCReport.gender === "M"
                  ? t("show.client_information.man")
                  : t("show.client_information.woman")}
              </p>
              <span>{t("show.client_information.gender")}</span>
            </div>
            <div className="information-text-item">
              <p>{clientKYCReport.nationality}</p>
              <span>{t("show.client_information.nationality")}</span>
            </div>
            <div className="information-text-item">
              <p>{clientKYCReport.curp}</p>
              <span>{t("show.client_information.curp")}</span>
            </div>
            <div className="information-text-item">
              <p>
                {clientKYCReport.typeDocument === "ELECTORAL_ID"
                  ? "INE"
                  : "Licencia de conducir"}
              </p>
              <span>{t("show.client_information.type_id")}</span>
            </div>
            <div className="information-text-item">
              <p>{clientKYCReport.numberDocument}</p>
              <span>{t("show.client_information.number_id")}</span>
            </div>
            {clientKYCReport.typeDocument === "ELECTORAL_ID" ? (
              <div className="information-text-item">
                <p>
                  {dayjs(clientKYCReport.expiryDate, "DD/MM/YYYY").format(
                    "DD-MMMM-YYYY"
                  )}
                </p>
                <span>{t("show.client_information.id_expiration")}</span>
              </div>
            ) : (
              <div className="information-text-item">
                <p>
                  {dayjs(clientKYCReport.expiryDate, "DD/MM/YYYY").format(
                    "DD-MMMM-YYYY"
                  )}
                </p>
                <span>{t("show.client_information.license_expiration")}</span>
              </div>
            )}
            {clientKYCReport.typeDocument === "ELECTORAL_ID" ? (
              <div className="information-text-item">
                <p>
                  {dayjs(clientKYCReport.expiryDate, "DD/MM/YYYY").format(
                    "DD-MMMM-YYYY"
                  )}
                </p>
                <span>{t("show.client_information.id_issuance")}</span>
              </div>
            ) : (
              <div className="information-text-item">
                <p>
                  {dayjs(clientKYCReport.expiryDate, "DD/MM/YYYY").format(
                    "DD-MMMM-YYYY"
                  )}
                </p>
                <span>{t("show.client_information.license_issuance")}</span>
              </div>
            )}
            <div className="information-text-item address">
              <p>{clientKYCReport.address}</p>
              <span>{t("show.client_information.address")}</span>
            </div>
          </div>

          <div className="client-kyc__security-docs">
            <div className="client-kyc__security-docs-item">
              <div className="docs-item-title">
                <h4>{t("show.client_information.id")}</h4>
              </div>
              <div className="docs-item-body">
                <div className="docs-item-body__image">
                  <img
                    src={clientKYCReport.documentsFront}
                    alt=""
                    className="kyc-images"
                  />
                  <p className="doc-position">
                    {t("show.client_information.front")}
                  </p>
                </div>
                <div className="docs-item-body__image">
                  <img
                    src={clientKYCReport.documentsBack}
                    alt=""
                    className="kyc-images"
                  />
                  <p className="doc-position">
                    {t("show.client_information.back")}
                  </p>
                </div>
              </div>
            </div>
            <div className="client-kyc__security-docs-item">
              <div className="docs-item-title">
                <h4>{t("show.client_information.license")}</h4>
              </div>
              <div className="docs-item-body">
                <div className="docs-item-body__doc">
                  {bankStatement && bankStatement.url && (
                    <UploadDropZone
                      allow="file,image"
                      fileDetails={{
                        url: bankStatement?.url || "",
                        type: bankStatement?.mime || "",
                      }}
                      height="120px"
                      path={{
                        fileName: "estado_cuenta",
                        folderName: `/${uploadioEnv}/user-${getUserId()}`,
                      }}
                    />
                  )}
                  <p className="doc-position">
                    {t("show.client_information.front")}
                  </p>
                </div>
              </div>
            </div>
            <div className="client-kyc__security-docs-item">
              <div className="docs-item-title">
                <h4>{t("show.client_information.proof_address")}</h4>
              </div>
              <div className="docs-item-body">
                <div className="docs-item-body__doc">
                  {proofOfAddress && proofOfAddress.url && (
                    <UploadDropZone
                      allow="file,image"
                      fileDetails={{
                        url: proofOfAddress?.url || "",
                        type: proofOfAddress?.mime || "",
                      }}
                      height="120px"
                      path={{
                        fileName: "comprobante_domicilio",
                        folderName: `/${uploadioEnv}/user-${getUserId()}`,
                      }}
                    />
                  )}
                  <p className="doc-position">
                    {t("show.client_information.front")}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="client-kyc__information-images">
            <h4>{t("show.client_information.bio_data")}</h4>
            <div className="information-images-container">
              {clientKYCReport.biometricData.map(
                (src: string, index: number) => (
                  <div
                    className="information-images-item-container"
                    key={index}
                  >
                    <div className="information-images-item">
                      <img src={src} alt="" />
                    </div>
                    <p>{`Foto ${index + 1}`}</p>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="dropdown-divider"></div>

      <div className="client-kyc__report-ids">
        <h3>{t("show.client_reports.reports")}</h3>
        <div className="client-kyc__report-ids-container">
          <Trans>
            <div className="report-ids-item">
              <p>
                {t("show.client_reports.moffin", {
                  moffinReportId: clientKYCReport.moffinReportId,
                })}
              </p>
              <a href="">{t("show.client_reports.watch_report")}</a>
            </div>
          </Trans>
        </div>
      </div>
    </article>
  );
};

export const ClientKYCNotPaid = () => {
  const { t } = useTranslation(["client", "routes"]);

  return (
    <article className="client-kyc-container">
      <div className="client-kyc__information__title">
        <h2>{t("show.client_kyc.security_info")}</h2>
        <p>{t("show.client_kyc.info")}</p>
      </div>

      <div className="client-kyc__main-status not-paid-kyc">
        <h3>{t("show.client_kyc.results")}</h3>

        <div className="client-kyc__status-item-container-decision">
          <h4>{t("show.client_kyc.decision")}</h4>
          <div className="client-kyc__status-item-trust">
            <div className="client-kyc__status-item">
              <p className="status-item__title">
                {t("show.client_kyc.result")}
              </p>
              <Chip
                icon={<CheckCircleOutlineIcon color="success" />}
                label={"PASSED"}
                className="status-item__chip"
                sx={{
                  backgroundColor: "#E8F5E9",
                  color: "#2E7D32",
                  padding: "4px 16px",
                }}
              />
            </div>
            <div className="client-kyc__status-item">
              <p className="status-item__title">{t("show.client_kyc.grade")}</p>
              <Chip
                icon={<CheckCircleOutlineIcon color="success" />}
                label={"PASSED"}
                className="status-item__chip"
                sx={{
                  backgroundColor: "#E8F5E9",
                  color: "#2E7D32",
                  padding: "4px 16px",
                }}
              />
            </div>
          </div>
        </div>
        <div className="client-kyc__status-item-container-criteria">
          <h4>{t("show.client_kyc.criteria")}</h4>
          <div className="client-kyc__status-item-container">
            <div className="client-kyc__status-item">
              <p className="status-item__title">{t("show.client_kyc.id")}</p>
              <Chip
                icon={<CheckCircleOutlineIcon color="success" />}
                label={"PASSED"}
                className="status-item__chip"
                sx={{
                  backgroundColor: "#E8F5E9",
                  color: "#2E7D32",
                  padding: "4px 16px",
                }}
              />
            </div>
            <div className="client-kyc__status-item">
              <p className="status-item__title">
                {t("show.client_kyc.selfie")}
              </p>
              <Chip
                icon={<CheckCircleOutlineIcon color="success" />}
                label={"PASSED"}
                className="status-item__chip"
                sx={{
                  backgroundColor: "#E8F5E9",
                  color: "#2E7D32",
                  padding: "4px 16px",
                }}
              />
            </div>
            <div className="client-kyc__status-item">
              <p className="status-item__title">
                {t("show.client_kyc.facemap")}
              </p>
              <Chip
                icon={<CheckCircleOutlineIcon color="success" />}
                label={"PASSED"}
                className="status-item__chip"
                sx={{
                  backgroundColor: "#E8F5E9",
                  color: "#2E7D32",
                  padding: "4px 16px",
                }}
              />
            </div>
            <div className="client-kyc__status-item">
              <p className="status-item__title">
                {t("show.client_kyc.extraction")}
              </p>
              <Chip
                icon={<CheckCircleOutlineIcon color="success" />}
                label={"PASSED"}
                className="status-item__chip"
                sx={{
                  backgroundColor: "#E8F5E9",
                  color: "#2E7D32",
                  padding: "4px 16px",
                }}
              />
              <span className="label-text">OK</span>
            </div>
            <div className="client-kyc__status-item">
              <p className="status-item__title">
                {t("show.client_kyc.images")}
              </p>
              <Chip
                icon={<CheckCircleOutlineIcon color="success" />}
                label={"PASSED"}
                className="status-item__chip"
                sx={{
                  backgroundColor: "#E8F5E9",
                  color: "#2E7D32",
                  padding: "4px 16px",
                }}
              />
            </div>
            <div className="client-kyc__status-item">
              <p className="status-item__title">{t("show.client_kyc.data")}</p>
              <Chip
                icon={<CheckCircleOutlineIcon color="success" />}
                label={"PASSED"}
                className="status-item__chip"
                sx={{
                  backgroundColor: "#E8F5E9",
                  color: "#2E7D32",
                  padding: "4px 16px",
                }}
              />
              <span className="label-text">OK</span>
            </div>
            <div className="client-kyc__status-item">
              <p className="status-item__title">{t("show.client_kyc.life")}</p>
              <Chip
                icon={<CheckCircleOutlineIcon color="success" />}
                label={"PASSED"}
                className="status-item__chip"
                sx={{
                  backgroundColor: "#E8F5E9",
                  color: "#2E7D32",
                  padding: "4px 16px",
                }}
              />
              <span className="label-text">OK</span>
            </div>
            <div className="client-kyc__status-item">
              <p className="status-item__title">
                {t("show.client_kyc.similarity")}
              </p>
              <Chip
                icon={<CheckCircleOutlineIcon color="success" />}
                label={"PASSED"}
                className="status-item__chip"
                sx={{
                  backgroundColor: "#E8F5E9",
                  color: "#2E7D32",
                  padding: "4px 16px",
                }}
              />
              <span className="label-text">OK</span>
            </div>
          </div>
        </div>
        <div className="modal-not-paid-kyc">
          <img src={clientImages.shield} alt="" className="kyc-shield" />
          <div className="modal-not-paid-kyc__body">
            <h4>{t("modals.not_paid_kyc.more_security")}</h4>
            <p>{t("modals.not_paid_kyc.pay")}</p>
          </div>
          <div className="modal-not-paid-kyc__footer">
            <button className="btn btn-plans">
              {t("modals.not_paid_kyc.plans")}
            </button>
          </div>
        </div>
      </div>
    </article>
  );
};
