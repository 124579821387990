import { KycStatus } from "../../views/profile/types";
import {
  DocStatus,
  TrustedUser,
  UserAccountStatus,
  UserStatus,
} from "../user/types";

export interface Client {
  id: number;
  name: string;
  surName: string;
  email: string;
  phone: string;
  totalTrips: number;
  lastTripDate: string;
  docOfficialDocuments: KycStatus;
  createDate: string;
  docBankStatement: DocStatus;
  docProofOfAddress: DocStatus;
  status: UserAccountStatus;
  isBlocked: UserStatus;
  trustedUser: TrustedUser;
}

export interface EditClient {
  id: number;
  name?: string;
  surName?: string;
  email?: string;
  phone?: string;
  totalTrips?: number;
  lastTripDate?: string;
  docOfficialDocuments?: KycStatus;
  createDate?: string;
  docBankStatement?: DocStatus;
  docProofOfAddress?: DocStatus;
  status?: UserAccountStatus;
  isBlocked?: UserStatus;
  trustedUser?: TrustedUser;
}

export interface ClientKYCReport {
  general: General;
  liveness: DecisionLabel;
  similarityMatch: DecisionLabel;
  extraction: DecisionLabel;
  dataReview: DecisionLabel;
  usabilityId: KycMoffinStatus;
  usabilitySelfie: KycMoffinStatus;
  usabilityFaceMap: KycMoffinStatus;
  imageReview: KycMoffinStatus;
  duplicateUser: string;
  documentsFront: string;
  documentsBack: string;
  name: string;
  lastName: string;
  birthDay: string;
  gender: string;
  nationality: string;
  curp: string;
  typeDocument: string;
  numberDocument: string;
  expiryDate: string;
  address: string;
  biometricData: string[];
  moffinReportId: string;
  age: string;
}

export type RiskScores = 100 | 50 | 25 | 0;

interface DecisionLabel {
  type: KycMoffinStatus;

  label: string;
}
interface General {
  result: KycMoffinStatus;

  score: RiskScores;
}

export enum KycMoffinStatus {
  NOT_EXECUTED = "NOT_EXECUTED",
  REJECTED = "REJECTED",
  WARNING = "WARNING",
  PASSED = "PASSED",
}
