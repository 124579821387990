import {
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  TextField,
} from "@mui/material";
import "./CreateHostReservation.scss";
import navbarImages from "../../../assets/images/navbar/index";
import { AddReservationForm, addReservationSchema } from "./form";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { addReservationByHost } from "../../../services/trip";
import { TripStatus, TripType } from "../../../models/trip/types";
import { getClientsByHost } from "../../../services/client";
import { mockIndexClientSearch } from "../../clients/index/form";
import { mockIndexCarSearch } from "../../car/index/form";
import { getCarsByUser } from "../../../services/car";
import {
  DateRange,
  DateRangePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { greenwichToMilitarTime } from "../../../lib/helpers/timeHandler";
import { useDisableDates } from "../../../hooks/disableDates";
import { Car } from "../../../models/car/types";
import { useHours } from "../../../hooks/hours";
import { getAddressesByCarID } from "../../../services/address";
import { useAddressTypes } from "../../../hooks/address";
import { Address } from "../../../models/address/types";
import { addressPrice, addressValue } from "../../../lib/helpers/textHandler";
import { AlertContext } from "../../../providers/alertProvider";
import {
  getInsuranceDays,
  getTripDays,
  isDateAvailable,
} from "../../../lib/helpers/dateHandler";
import { Link } from "react-router-dom";
import { InsuranceType } from "../../../models/insurance/types";
import { getInsuranceAndRefresh } from "../../../services/insurance";
import Loading from "../../../components/loading/component";

export const CreateHostReservation = () => {
  const { t } = useTranslation(["hostReservation", "routes"]);
  const queryClient = useQueryClient();
  const [car, setCar] = useState<Car>();
  const [dates, setDates] = useState<DateRange<Dayjs>>([null, null]);
  const { formatHours } = useHours(30);
  const [finalLeftHours, setFinalLeftHours] = useState<string[]>(formatHours);
  const [finalRightHours, setFinalRightHours] = useState<string[]>(formatHours);
  const { setOpenAlert, setAlertMessage } = useContext(AlertContext);
  const [warning, setWarning] = useState(true);
  const [tripLoading, setTripLoading] = useState(false);

  const { data: insurance } = useQuery(
    ["InsuranceCar", car?.id],
    () => getInsuranceAndRefresh(car?.id),
    {
      enabled: !!car,
    }
  );

  useEffect(() => {
    if (formatHours) {
      setFinalLeftHours(formatHours);
      setFinalRightHours(formatHours);
    }
  }, [formatHours]);

  const { blockDates } = useDisableDates({
    carId: car?.id.toString() || "0",
  });

  const { data: clients, isLoading: loadingClients } = useQuery(
    ["clients"],
    () => getClientsByHost(mockIndexClientSearch)
  );

  const { data: addresses, isLoading: loadingAddresses } = useQuery(
    ["addresses", car?.id],
    () => getAddressesByCarID(car?.id),
    {
      enabled: !!car,
    }
  );

  const { main, extra } = useAddressTypes(addresses || []);

  const mainAddress = main.map((address: Address) => {
    return (
      <MenuItem
        style={{ whiteSpace: "normal" }}
        value={addressValue(address)}
        key={address.id + "-" + address.type + "-" + address.price}
      >
        {`${t("add.map.main")} ${address.suburb} ${address.zipCode}, ${
          address.city
        }, ${address.state}, ${address.country} ${addressPrice(address)}`}
      </MenuItem>
    );
  });

  const extraAddress = extra.map((address: Address) => {
    return (
      <MenuItem
        style={{ whiteSpace: "normal" }}
        value={addressValue(address)}
        key={address.id + "-" + address.type + "-" + address.price}
      >
        {`📍 ${address.comercialName} ${addressPrice(address)}`}
      </MenuItem>
    );
  });

  const items = mainAddress.concat(extraAddress);

  const formatDates = (newValue: DateRange<Dayjs>) => {
    setDates(newValue);
    if (!newValue[0] || !newValue[1]) return;

    const startDate = dayjs(newValue[0]);
    const endDate = dayjs(newValue[1]);

    formikAddReservation.setValues({
      ...formikAddReservation.values,
      pickUpDate: startDate,
      returnDate: endDate,
    });
  };

  const handleDates = (newValue: DateRange<dayjs.Dayjs>) => {
    formatDates(newValue);
  };

  const { data: cars, isLoading: loadingCars } = useQuery(["cars"], () =>
    getCarsByUser(mockIndexCarSearch)
  );

  const fetchAddReservation = async (values: AddReservationForm) => {
    setTripLoading(true);
    try {
      const newTrip = {
        carId: values.carId,
        hostId: values.hostId,
        driverUserId: values.driverUserId,
        pickUpAddressId: values.pickUpAddressId,
        returnAddressId: values.returnAddressId,
        type: TripType.DEACTIVATED,
        status: TripStatus.SEND_REQUEST_RESERVATION,
        createDate: dayjs(new Date()).format("YYYY-MM-DD"),
        pickUpDate: values.pickUpDate.format("YYYY-MM-DD"),
        pickUpTime: values.pickUpTime + ":00",
        returnDate: values.returnDate.format("YYYY-MM-DD"),
        returnTime: values.returnTime + ":00",
        policyNumber: "",
        carPrice: carFinalPrice.toString(),
        insurancePrice: insuranceAmount.toString(),
        pickUpReturnPrice: pickUpReturnFinalPrice.toString(),
        bookingFeePrice: bookingFeePrice.toString(),
        depositAmount: insurance?.depositAmount || "",
        totalPrice: finalPrice.toString(),
        timeZone: "",
        stripePayId: "12345",
      };

      const response = await addReservationByHost(newTrip);

      if (!response || response.status != 201) return;

      formikAddReservation.resetForm();
      queryClient.resetQueries(["clients"]);
      setAlertMessage({
        message: "Tu reservacion fue creada",
        type: "success",
      });
    } catch (e) {
      setAlertMessage({
        message: "No se pudo crear tu reservación",
        type: "error",
      });
    } finally {
      setOpenAlert(true);
      setTripLoading(false);
    }
  };

  const formikAddReservation = useFormik<AddReservationForm>({
    initialValues: {
      carId: 0,
      hostId: 0,
      driverUserId: 0,
      status: TripStatus.SEND_REQUEST_RESERVATION,
      pickUpAddressId: 0,
      pickUpPrice: 0,
      pickUpType: 3,
      pickUpDate: dayjs(),
      pickUpTime: "",
      returnAddressId: 0,
      returnPrice: 0,
      returnType: 3,
      returnDate: dayjs().add(2, "days"),
      returnTime: "",
    },
    validationSchema: addReservationSchema,
    onSubmit: fetchAddReservation,
  });

  useEffect(() => {
    setDates([
      dayjs(formikAddReservation.values.pickUpDate),
      dayjs(formikAddReservation.values.returnDate),
    ]);
  }, []);

  useEffect(() => {
    if (car) {
      formikAddReservation.setFieldValue("hostId", car.userId);
    }
  }, [car]);

  useEffect(() => {
    if (formikAddReservation.values.carId) {
      formikAddReservation.setFieldValue("pickUpAddressId", 0);
      formikAddReservation.setFieldValue("returnAddressId", 0);
    }
  }, [formikAddReservation.values.carId]);

  useEffect(() => {
    if (
      formikAddReservation.values.pickUpDate &&
      formikAddReservation.values.returnDate
    ) {
      const availability = isDateAvailable(
        [
          formikAddReservation.values.pickUpDate,
          formikAddReservation.values.returnDate,
        ],
        blockDates
      );

      setWarning(!availability);
    }
  }, [
    formikAddReservation.values.pickUpDate,
    formikAddReservation.values.returnDate,
    blockDates,
  ]);

  const tripDays = getTripDays(
    formikAddReservation.values.pickUpDate,
    formikAddReservation.values.pickUpTime,
    formikAddReservation.values.returnDate,
    formikAddReservation.values.returnTime
  );

  const insuranceDays = getInsuranceDays(
    insurance?.type || InsuranceType.QUALITAS,
    formikAddReservation.values.pickUpDate,
    formikAddReservation.values.pickUpTime,
    formikAddReservation.values.returnDate,
    formikAddReservation.values.returnTime
  );

  const carFinalPrice = tripDays
    ? parseFloat(car ? car.price : "0") * tripDays || 0
    : 0;
  const insuranceAmount = insuranceDays
    ? parseFloat(insurance?.customerPrice ? insurance?.customerPrice : "0") *
        insuranceDays || 0
    : 0;
  const bookingFeePrice = 100;
  const pickUpReturnFinalPrice =
    formikAddReservation.values.pickUpPrice +
    formikAddReservation.values.returnPrice;
  const finalPrice =
    carFinalPrice + insuranceAmount + bookingFeePrice + pickUpReturnFinalPrice;

  const handleAddressSelector = (
    e: SelectChangeEvent<string>,
    type: "pickUp" | "return"
  ) => {
    const data = e.target.value.split("-");
    formikAddReservation.setFieldValue(type + "AddressId", parseInt(data[0]));
    formikAddReservation.setFieldValue(type + "Type", parseInt(data[1]));
    formikAddReservation.setFieldValue(type + "Price", parseFloat(data[2]));
  };

  useEffect(() => {
    formikAddReservation.validateForm();
  }, []);

  console.log(formikAddReservation.isValid);

  return (
    <article className="add-reservation-host-container">
      <div className="add-reservation-host__join-container">
        <div className="add-reservation-host__title-container">
          <div className="add-reservation-host__route">
            <p className="add-reservation-host__route__first">
              {t("add.manages")}
            </p>
            <img
              src={navbarImages.arrowRight}
              className="add-reservation-host__route__arrow"
            />
            <p className="add-reservation-host__route__second">
              {t("add.reservations")}
            </p>
          </div>
          <h1
            data-testid="my-cars-title"
            className="add-reservation-host__title"
          >
            {t("add.create_reservation")}
          </h1>
          <p className="add-reservation-host__subtitle">{t("add.manual")}</p>
        </div>
      </div>
      <div className="add-reservation-host-body__container">
        <div className="add-reservation-host-body">
          <div className="add-reservation-host-body__title-container">
            <h2 className="add-reservation-host-body__title">
              {t("add.details")}
            </h2>
            <span className="add-reservation-host-host__subtitle">
              {t("add.require_fields")}
            </span>
          </div>
          <div className="add-reservation-host-body__inputs">
            <div className="add-reservation-host-body__input-container">
              <InputLabel htmlFor="custom-text-field" required>
                {t("add.client.label")}
              </InputLabel>
              {clients && clients.length > 0 && !loadingClients ? (
                <Select
                  name="driverUserId"
                  value={formikAddReservation.values.driverUserId}
                  onChange={formikAddReservation.handleChange}
                  onBlur={formikAddReservation.handleBlur}
                  required
                  className="add-reservation-host-body__input"
                >
                  <MenuItem value={0} disabled>
                    {t("add.client.placeholder")}
                  </MenuItem>
                  {clients.map((client) => (
                    <MenuItem value={client.id} key={client.id}>
                      {client.name}
                    </MenuItem>
                  ))}
                </Select>
              ) : (
                <Skeleton className="add-reservation-host-body__input-skeleton" />
              )}
            </div>
            <div className="add-reservation-host-body__input-container">
              <InputLabel htmlFor="custom-text-field" required>
                {t("add.car.label")}
              </InputLabel>
              {cars && cars.length > 0 && !loadingCars ? (
                <Select
                  className="add-reservation-host-body__input"
                  name="carId"
                  value={formikAddReservation.values.carId}
                  onChange={formikAddReservation.handleChange}
                  onBlur={formikAddReservation.handleBlur}
                  required
                >
                  <MenuItem value={0} disabled>
                    {t("add.car.placeholder")}
                  </MenuItem>
                  {cars.map((car) => (
                    <MenuItem
                      value={car.id}
                      key={car.id}
                      onClick={() => setCar(car)}
                    >{`${car.brand} ${car.model} ${car.year}`}</MenuItem>
                  ))}
                </Select>
              ) : (
                <Skeleton className="add-reservation-host-body__input-skeleton" />
              )}
            </div>
            <div className="add-reservation-host-body__input-container">
              <InputLabel htmlFor="custom-text-field" required>
                {t("add.delibery.label")}
              </InputLabel>
              {addresses && addresses.length > 0 && !loadingAddresses ? (
                <Select
                  className="add-reservation-host-body__input"
                  name="pickUpAddressId"
                  value={
                    formikAddReservation.values.pickUpAddressId +
                    "-" +
                    formikAddReservation.values.pickUpType +
                    "-" +
                    formikAddReservation.values.pickUpPrice
                  }
                  onChange={(e) => handleAddressSelector(e, "pickUp")}
                  required
                >
                  <MenuItem value={"0-3-0"} disabled>
                    {t("add.delibery.placeholder")}
                  </MenuItem>
                  {items}
                </Select>
              ) : (
                <Skeleton className="add-reservation-host-body__input-skeleton" />
              )}
            </div>
            <div className="add-reservation-host-body__input-container">
              <InputLabel htmlFor="custom-text-field" required>
                {t("add.return.label")}
              </InputLabel>
              {addresses && addresses.length > 0 && !loadingAddresses ? (
                <Select
                  className="add-reservation-host-body__input"
                  name="returnAddressId"
                  value={
                    formikAddReservation.values.returnAddressId +
                    "-" +
                    formikAddReservation.values.returnType +
                    "-" +
                    formikAddReservation.values.returnPrice
                  }
                  onChange={(e) => handleAddressSelector(e, "return")}
                  required
                >
                  <MenuItem value={"0-3-0"} disabled>
                    {t("add.return.placeholder")}
                  </MenuItem>
                  {items}
                </Select>
              ) : (
                <Skeleton className="add-reservation-host-body__input-skeleton" />
              )}
            </div>
            <div className="add-reservation-host-body__input-container">
              <div className="un-container-date">
                {car && (
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    localeText={{ start: "", end: "" }}
                  >
                    <DateRangePicker
                      value={dates}
                      inputFormat="DD/MM/YYYY"
                      disablePast
                      onChange={(newValue) => {
                        handleDates(newValue);
                      }}
                      renderInput={(startProps, endProps) => (
                        <div className="dates">
                          <div className="un-desde">
                            <label className="w-100 labels-dates">
                              {t("add.dates.from")}
                            </label>
                            <div className="d-flex align-items-center position-relative">
                              <TextField
                                {...startProps}
                                className="datepicker start"
                              />
                              {finalLeftHours.length !== 0 && (
                                <Select
                                  labelId="time-left"
                                  id="time-left"
                                  label=""
                                  className="hour"
                                  defaultValue=""
                                  value={formikAddReservation.values.pickUpTime}
                                  onChange={(event) =>
                                    formikAddReservation.setFieldValue(
                                      "pickUpTime",
                                      event.target.value
                                    )
                                  }
                                >
                                  {finalLeftHours.map((hour) => (
                                    <MenuItem
                                      key={greenwichToMilitarTime(hour)}
                                      value={greenwichToMilitarTime(hour)}
                                    >
                                      {hour}
                                    </MenuItem>
                                  ))}
                                </Select>
                              )}
                            </div>
                            {formikAddReservation.touched.pickUpTime
                              ? formikAddReservation.errors.pickUpTime
                              : ""}
                          </div>
                          <div className="un-hasta">
                            <label className="labels-dates">
                              {t("add.dates.to")}
                            </label>
                            <div className="d-flex align-items-center position-relative">
                              <TextField
                                {...endProps}
                                className="datepicker end"
                              />
                              {finalRightHours.length !== 0 && (
                                <Select
                                  labelId="time-right"
                                  id="time-right"
                                  label=""
                                  className="hour"
                                  defaultValue=""
                                  value={formikAddReservation.values.returnTime}
                                  onChange={(event) =>
                                    formikAddReservation.setFieldValue(
                                      "returnTime",
                                      event.target.value
                                    )
                                  }
                                >
                                  {finalRightHours.map((hour) => (
                                    <MenuItem
                                      key={greenwichToMilitarTime(hour)}
                                      value={greenwichToMilitarTime(hour)}
                                    >
                                      {hour}
                                    </MenuItem>
                                  ))}
                                </Select>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    />
                  </LocalizationProvider>
                )}
              </div>
              {warning && <span>{t("add.dates.warning")}</span>}
            </div>
          </div>
          <div className="add-reservation-host-footer">
            {tripLoading ? (
              <button className="add-reservation-host-footer__loading" disabled>
                <Loading height="15px" />
              </button>
            ) : (
              <>
                <Link
                  to={`/${t("routes:manage")}/${t("routes:trips")}`}
                  className="add-reservation-host-footer__cancel"
                >
                  {t("add.cancel_button")}
                </Link>
                <button
                  className="add-reservation-host-footer__add"
                  style={{
                    background:
                      !formikAddReservation.isValid ||
                      !formikAddReservation.dirty
                        ? "var(--Colors-Custom-colors-Disabled-bg, #E9E9E9)"
                        : "var(--Colors-Brand-colors-Primary-color, #b72446)",
                  }}
                  disabled={
                    !formikAddReservation.isValid || !formikAddReservation.dirty
                  }
                  onClick={() => formikAddReservation.handleSubmit()}
                >
                  {t("add.add_reservation_button")}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </article>
  );
};
