import { useQuery } from "react-query";
import {
  getFileDetailsIfExist,
  uploadioEnv,
} from "../../services/fileUploader";
import { Car } from "../../models/car/types";
import { CarStatus } from "../../models/car/types";
import carImagesIndex from "../../assets/images/car/index";
import { Skeleton } from "@mui/material";

const CarDefaultImage = ({
  car,
  width,
  height,
}: {
  car: Car;
  width?: number;
  height?: number;
}) => {
  const { data: carImages, isLoading } = useQuery(["image", car.id], () =>
    getFileDetailsIfExist(
      `/${uploadioEnv}/user-${car.userId}/car-${car.id}/car_photo_1`,
      `/${uploadioEnv}/user-${car.userId}/car-${car.id}`
    )
  );

  return (
    <div className="car-image">
      {isLoading ? (
        <Skeleton variant="rectangular" height={height} width={width} />
      ) : carImages?.fileUrl ? (
        <img
          className="car"
          style={{
            opacity:
              car.status === CarStatus.APPROVED ||
              car.status === CarStatus.PENDING
                ? "1"
                : "0.6",
          }}
          alt="car image"
          src={
            carImages?.fileUrl.replace("raw", "thumbnail-my-cars") +
            `?v=${carImages.etag}`
          }
        />
      ) : (
        <div
          style={{
            display: "grid",
            placeContent: "center",
            width: width ? `${width}px` : "315px",
          }}
        >
          <img
            className="car empty-image"
            style={{
              opacity:
                car.status === CarStatus.APPROVED ||
                car.status === CarStatus.PENDING
                  ? "1"
                  : "0.6",
            }}
            alt="car no image"
            src={carImagesIndex.defaultCar}
          />
        </div>
      )}
    </div>
  );
};

export default CarDefaultImage;
