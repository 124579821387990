import { mockAddress } from "./../../../models/address/mockups";
import { defaultUser } from "./../../../models/user/mockups";
import { createContext } from "react";
import { Car } from "../../../models/car/types";
import { User } from "../../../models/user/types";
import { Address } from "../../../models/address/types";
import { placeComponents } from "../../home/types";
import dayjs from "dayjs";

export const ShowContext = createContext<{
  car: Car | undefined;
  host: User | undefined;
  addresses: Address[] | undefined;
}>({ car: undefined, host: defaultUser, addresses: [mockAddress] });

export interface TripForm {
  pickUpPrice: number;
  pickUpType: number;
  pickUpAddressId: number;
  pickUpAddress?: placeComponents;
  returnPrice: number;
  returnType: number;
  pickUpDate?: dayjs.Dayjs;
  returnAddressId: number;
  returnAddress?: placeComponents;
  pickUpTime: string;
  returnDate?: dayjs.Dayjs;
  returnTime: string;
}

export interface imageUpload {
  type: string;
  fileUrl: string;
  filePath: string;
  size: number;
  lastModified: number;
}
